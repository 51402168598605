// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography, TextField,NativeSelect,Modal } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Paper from '@mui/material/Paper'
import ReactSelect from "react-select";

import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Controller, Form, useForm } from "react-hook-form";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Card from '@mui/material/Card';
import { ToastContainer, toast } from 'react-toastify';
import TrackHoursRegistration from '../TrackHoursRegistration'
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import moment from 'moment'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const TrackHours = () => {
  document.title = "TrackHours | Maevisory";

  const [isLoading, setLoading] = useState(false)
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const Userid = localStorage.getItem('userid')

  
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
 
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    // defaultValues: defValues
  })
  const [refresh, setRefresh] = useState(false)
  function applyRefresh() {
    setRefresh(!refresh)
  }
  const navigate = useNavigate();
  const [TrackHoursid,setTrackHoursid]=useState('')
  const [TrackHours, setTrackHours] = React.useState(false);
  const handleTrackHours= () => {
    setTrackHours(true)
  };
  const handlecloseTrackHours = () => {
    setTrackHoursid('')
    // setLomnRequestid('')
    //  setPatientNamee('')
    setTrackHours(false)
  };
  const columns = [
    {
      field: 'trackType',
      headerClassName: 'super-app-theme--header',
      headerName: 'Track Type',
      cellClassName: 'gridCell',
      flex: 0.19,
      renderCell: cellValues => (
        <Button
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            // openUserRegistration(event,cellValues.row.id )
            setTrackHoursid(cellValues.row.id)
          }}
        >{`${cellValues.row.trackType}`}</Button>
      )
    },
  
    {
      field: 'trackDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'Date',
      flex: 0.18
    },
    {
      field: 'therpistName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Therapist Name',
      flex: 0.26
    },
    // {
    //   field: 'trackTimeStart',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'Start Time',
    //   flex: 0.16
    // },
    // {
    //   field: 'trackTimeEnd',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'End Time',
    //   flex: 0.16
    // },
    
    {
      field: 'levelOne',
      headerClassName: 'super-app-theme--header',
      headerName: 'Level One',
      flex: 0.26
    },
    {
      field: 'levelTwo',
      headerClassName: 'super-app-theme--header',
      headerName: 'Level Two',
      flex: 0.26
    },
    {
      field: 'approveDateTime',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approved Date',
      flex: 0.35,
      renderCell: (params) => {
        return params.value ? moment(params.value).format('MM/DD/YYYY HH:mm') : '';
      },
    },
    {
      field: 'approvedBy',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approved By',
      flex: 0.23
    },
    {
      field: 'approve',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approve',
      flex: 0.16,
      renderCell: cellValues => {
        return cellValues.row.approve ? (
          <CheckIcon style={{ color: 'green' }} />
        ) : (
          <CloseIcon style={{ color: 'red' }} />
        )
      }
    },
    {
      field: 'inActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.16,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {
                     
                      inActive: true,
                      id: cell.row.id
                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/TrackHours/addTrackHours`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }
   
  ]
  useEffect(() => {
    if(TrackHoursid>0)
    {
      handleTrackHours()
    }
  
  
    },[TrackHoursid]);
  const [rows, setRows] = useState(null)
  const [rowsTrack, setRowsTrack] = useState(null)
  const [selectedValue, setSelectedValue] = useState('')
  const handleChangetype = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    applyRefresh(newValue)
    console.log("Selected value my kya ha : " ,  newValue)
  }
  const onFilterSearchtype = event => {
    if (userType ==='Supervisor' || userType ==="Admin"){
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/TrackHours/getTrackHours?requestString=${selectedValue}`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })
      .catch(error => {})
    } else {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/TrackHours/getTrackHours?requestString=${selectedValue}&Therpistid=${Userid}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
          setData(response)
        })
        .catch(error => {})

    }

  }
  const [Data ,setData ]= useState([])

  useEffect(() => {
    if (userType ==='Supervisor' || userType ==="Admin"){
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/TrackHours/getTrackHours?requestString=${selectedValue}`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
        setData(response)
      })
      .catch(error => {})
    
    }
  }, [refresh,selectedValue])
  useEffect(() => {
    if (userType  == 'Therapist'){
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/TrackHours/getTrackHours?requestString=${selectedValue}&Therpistid=${Userid}`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
        setData(response)
      })
      .catch(error => {})
    
    }
  }, [refresh,selectedValue])
 
  const openUserRegistration = (event, id = 0) => {
    navigate('/TrackHoursRegistration', { state: { uid: id } })
  }
  const [value, setvalue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };
  const [fromDate, setfromDate] = React.useState('')
  const [toDate, settoDate] = useState('')
  const onFilterSearch = event => {
    axios
        .get(
          `${process.env.REACT_APP_API_URL}/TrackHours/getSummaryTrackHours?fromDate=${fromDate}&toDate=${toDate}&userId=${Userid}`,
          { headers }
        )
        .then(response => {
          console.log('response.data: ', response)
          setRowsTrack(response)
          setLoading(false)
        })
        .catch(error => {
          console.error('There was an error!', error)
        })

  }
  const style = {
    position: 'absolute',
    top: '54%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.5)',
    padding: '15px',
    borderRadius: '8px',
    maxHeight: '89vh',
    // overflow: 'auto',

  };
  const formatTime = (timeInMinutes) => {
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    return `${hours} hr ${minutes > 0 ? `${minutes} mnts` : ''}`;
  };
  const [searchClicked, setSearchClicked] = useState(false);

const handleDownload = async () => {
  const doc = new jsPDF();
  doc.text('Time Tracking Report', 14, 20);
  // Define the columns
  const columns = [
          { header: 'Track Date', dataKey: 'trackDate'},
          { header: 'Track Time Start', dataKey: 'trackTimeStart'},
          { header: 'Track Time End', dataKey: 'trackTimeEnd'},
          { header: 'Track Type', dataKey: 'trackType'},
          { header: 'Location Site', key: 'locationSite'},
          { header: 'Level Two', dataKey: 'levelTwo'},
          { header: 'Level One', dataKey: 'levelOne'},
  ];

  // Map data to the rows
  const rows = Data.map(item => ({
    id: item.id,
        trackDate: item.trackDate,
        trackTimeStart: item.trackTimeStart,
        trackTimeEnd: item.trackTimeEnd,
        trackType: item.trackType,
        locationSite: item.locationSite,
        levelOne: item.levelOne,
        levelTwo: item.levelTwo,
  }));

 
  doc.autoTable({
    head: [columns.map(col => col.header)],
    body: rows.map(item => Object.values(item)),
    startY: 30, // Adjust starting point
  });

  // Generate the file name with the current date
  const fileName = `TimeTracking_Report${moment(new Date()).format('DD_MMM_YY')}.pdf`;

  // Save the PDF
  doc.save(fileName);
};

const [TherapistList, setTherapistList] = useState([]);
const [seletedTherpist, setseletedTherpist] = useState(null);

const onTherapistSearch = (therapistId) => {
  setLoading(true);
  axios
    .get(`${process.env.REACT_APP_API_URL}/TrackHours/getTrackHours?Therpistid=${therapistId}`, {
      headers
    })
    .then(response => {
      setLoading(false);
      setRows(response); // Assuming response.data contains the data
    })
    .catch(error => {
      setLoading(false);
      console.error("Error fetching user notes:", error);
    });
};
const userType = localStorage.getItem('User_Type');

useEffect(() => {
  // POST request using axios inside useEffect React hook
  axios
    .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, {
      headers
    })
    .then(response => {
      var prac = [];
      response.forEach(field => {
        prac.push({
          label: field.userName,
          value: field.id
        });
      });
      setTherapistList(prac);



    })
    .catch(error => { });

}, []);

  return (
    <>
  <Modal
          open={TrackHours}
          onClose={handlecloseTrackHours}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} 
        >
          <Box sx={style}>
          <Headroom >
            <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            // textAlign: 'left',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Track Hours Registration

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                          onClick={handlecloseTrackHours}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>

        


            <Grid item xs={12} sm={12} xl={12} >
            <Card bordered={false} style={{
              padding: '5px',
              maxHeight: '57vh',
              overflowY: 'auto',
              marginTop: '4px',
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: '#3676c4 transparent', // thumb color and track color
              '&::-webkit-scrollbar': {
                width: 8, /* Width of vertical scrollbar */
                height: 8, /* Height of horizontal scrollbar */
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#3676c4', /* Color of the thumb */
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', /* Color of the track */
              },
              /* Adjust the width for the horizontal scrollbar */
              '&::-webkit-scrollbar-thumb:horizontal': {
                width: 4, /* Width of horizontal scrollbar */
              },
            }} > 
            <TrackHoursRegistration uid={TrackHoursid} handleClose={handlecloseTrackHours}  selectedValue={selectedValue} ></TrackHoursRegistration>
           
             
              </Card>
              <ToastContainer
             position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
             
              />
            </Grid>


          </Box>
        </Modal>
      <div className="page-content">
            <Container >
            <Grid container spacing={1}>
                <Grid item xs={0.9} sm={0.9} xl={0.9}>
                <Typography style={{color:'black',fontSize:'18px',marginTop:'6px'}}>
                 Type:
              </Typography>
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <NativeSelect
                          onChange={handleChangetype}
                        
                          value={selectedValue}
                          
                        
                          style={{
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: '400',
                            lineHeight: '22px',
                            letterSpacing: '0px',
                            textAlign: 'left',
                            borderBottom: 'none',
                            textDecoration: 'none',width:'300px'
                          }}
                        >
                        
                          {/* <option value='All'>All</option> */}
                          <option value='all'>All</option>
                          <option value='pending'>Pending</option>
                          <option value='approved'>Approved</option>

                        </NativeSelect>
                </Grid>
                <Grid  item xs={1.8} sm={1.8} xl={1.8}>
                <Button
                  className="Button"
                  onClick={handleDownload}
                  fullWidth
                  disabled={!Data || Data.length === 0}
                  variant='contained' style={{fontSize:'14px',fontWeight:'bold',
                  }}
                  type="submit"
                >
                  Download
                  <DownloadForOfflineIcon />
                </Button>
     
          

              </Grid>
                
                <Grid  item xs={1.6}  sm={1.6} xl={1.6} >
                <Button  className="Button" onClick={onFilterSearchtype} variant='contained' style={{fontSize:'14px',fontWeight:'bold',
                  }}>
                 <CachedOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px',
                  
                }}
              ></CachedOutlinedIcon>
                  Refresh
                </Button>
                </Grid>
                {userType ==='Therapist' ? (
                <Grid  item xs={1.8} >
                <Button  className="Button" onClick={handleTrackHours} variant='contained' style={{fontSize:'14px',fontWeight:'bold',
                  }}>
                <AddOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px',
                  
                }}
              ></AddOutlinedIcon>
                  Add New
                </Button>
                </Grid>
                ) :
                (
                  <Grid  item xs={1.8} ></Grid>
                )
                }
               
            
              </Grid>
              {userType ==='Supervisor' || userType ==='Admin'  ? (
            <Paper elevation={4} style={{
              padding: '16px', backgroundColor: '#f5f5f5', borderRadius: '8px', marginBottom: '16px', width: '500px', margin: '10px', border: '2px solid #326C1D',

            }}>
              <Typography variant="h6" style={{ marginBottom: '12px', fontWeight: 'bold', color: '#333' }}>
                Filter by Therapist
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography style={{ color: '#333', fontSize: '16px', marginRight: '8px', minWidth: '100px' }}>
                  Therapist:
                </Typography>
                <ReactSelect
                  options={TherapistList}
                  value={TherapistList.find(option => option.value === getValues("therapistsId"))}
                  onChange={selectedOption => {
                    setValue("therapistsId", selectedOption.value);
                    setseletedTherpist(selectedOption.value);
                    onTherapistSearch(selectedOption.value); // Call the filter function when an option is selected
                  }}
                  styles={{
                    container: (base) => ({
                      ...base,
                      width: '100%',
                      maxWidth: '300px',
                    }),

                  }}
                  placeholder="Select Therapist"
                />
              </Box>
            </Paper>
            ) :''}
              {/* <div style={{ width: 200, marginTop: '5px', marginBottom: '0px' }}>
                <Button sx={{ mb: 1 }} className="Button" onClick={openUserRegistration} variant='contained' style={{fontSize:'14px',fontWeight:'bold'}}>
                <AddOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px'
                }}
              ></AddOutlinedIcon>
                  Add New
                </Button>
              </div> */}
              <Grid container spacing={1}>
              <Grid item xs={12} sm={12} xl={12}>
          <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="lab API tabs example"
              >
                <Tab value={0} label="All"/>
                <Tab value={1} label="Summary"/>
                

              </TabList>
            </Box>
            <TabPanel value={0}>
            <Box mb={0} style={{ marginTop: 0 }}>
              {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rows === null || rows.length === 0 ? ("No Record Found") : (
                    <DataGrid
                      rows={rows}
                      classes={gridClasses}
                      rowHeight={25}
                      columns={columns}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
                )}
              </Box>
            </TabPanel>
            <TabPanel value={1}>
            <Grid container spacing={1}>
            <Grid item xs={1.5} sm={1.5} xl={1.5}>
            <Typography style={{marginTop:'8px',color:'black',}}> From Date </Typography>
            </Grid>
            <Grid item xs={3} sm={3} xl={3}>
            <Controller
                name='fromDate'
                {...register('fromDate')}
                defaultValue={fromDate}
                control={control}
                render={({ field }) => (
                  <TextField
                    // isClearable
                    size='small'
                    type='date'
                    // disabled={isChecked == true ? (true) : (false)}
                    {...field}
                    fullWidth
                    value={fromDate}
                    // error={errors.fromDate}
                    onChange={e => {
                      setValue('fromDate', e.target.value)
                      setfromDate(e.target.value)

                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} xl={1}> </Grid>
            <Grid item xs={1} sm={1} xl={1}>
            <Typography style={{marginTop:'8px',color:'black',}}> To Date </Typography>
            </Grid>
            <Grid item xs={3} sm={3} xl={3}>
            <Controller
                name='toDate'
                defaultValue={toDate}

                {...register('toDate')}
                control={control}
                render={({ field }) => (
                  <TextField
                    // isClearable
                    size='small'
                    type='date'
                    // disabled={isChecked == true ? (true) : (false)}
                    {...field}
                    fullWidth
                    value={toDate}
                    // error={errors.fromDate}
                    onChange={e => {
                      setValue('toDate', e.target.value)
                      settoDate(e.target.value)

                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2.5} sm={2.5} xl={2.5}>
            <Button sx={{ mb: 1 }} className="Button"
            //  onClick={onFilterSearch}
            // onClick={() => { setSearchClicked(true); onFilterSearch(); }} 
            disabled={!fromDate || !toDate} // disable the button if either value is falsy
            onClick={() => {
              if (!fromDate || !toDate) {
                // display an error message or do something else to indicate that the fields are required
                alert("Please enter both a from date and a to date.");
                return;
              }
              setSearchClicked(true);
              onFilterSearch();
            }}
              variant='contained' style={{fontSize:'14px',fontWeight:'bold',float:'right'}}>
                <SearchOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px'
                }}
              ></SearchOutlinedIcon>
                  Search
                </Button>
            </Grid>
            {/* <Grid item xs={12} sm={12} xl={12}>
                {rowsTrack != null && rowsTrack.length > 0
                  ? rowsTrack.map((row, index) => {
                    return(
                      <Paper
                      variant='outlined'
                      square
                      style={{
                        backgroundColor: '#F0F6F8',
                        borderRadius: '15px',
                        marginTop: '5px',
                        borderColor: '#377562',
                        maxWidth: '100%',
                        padding: '.9rem',
                        marginBottom: '1rem',
                        marginLeft: '0 .2rem'
                      }}
                    >
                     <Grid container spacing={1}>
                     <Grid item xs={6} sm={6} xl={6}>
                      <Typography style={{ fontSize: '14px' }}>
                       <b>Activity Type:</b>  {row.activityType}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} xl={6}>
                      <Typography style={{ fontSize: '14px',color:'black' }}>
                      <b>Track Hours :</b> {formatTime(row.trackHours)}
                        </Typography>
                      </Grid>
                     </Grid>
                    </Paper>
                    )
                   
                  })
                  : 'No Record Found'}
                </Grid> */}
<Grid item xs={12} sm={12} xl={12}>
  {searchClicked && rowsTrack != null && rowsTrack.length === 0
    ? 'No Record Found'
    : rowsTrack != null && rowsTrack.length > 0
      ? rowsTrack.map((row, index) => {
        return (
          <Paper
            variant='outlined'
            square
            style={{
              backgroundColor: '#F0F6F8',
              borderRadius: '15px',
              marginTop: '5px',
              borderColor: '#377562',
              maxWidth: '100%',
              padding: '.9rem',
              marginBottom: '1rem',
              marginLeft: '0 .2rem'
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  <b>Activity Type:</b> {row.activityType}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px', color: 'black' }}>
                  <b>Track Hours:</b> {formatTime(row.trackHours)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        );
      })
      : ''}
</Grid>
            </Grid>
              </TabPanel>
          </TabContext>


              </Grid>
            

              </Grid>
             
            </Container>
          </div>
    </>
      
     
  )
}

export default TrackHours
