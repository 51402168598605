import React, { useEffect, useReducer, useState, Fragment } from 'react'

import { Grid, Button, MenuItem, Typography, Divider } from '@mui/material'

// import MuiAlert from '@mui/material'
import axios from 'axios'
import Box from '@mui/material/Box'
import ReactSelect from 'react-select'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import PermIdentity from '@mui/icons-material/PermIdentity'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import TextField from '@mui/material/TextField'
// import { useLocation } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'

import { Controller, useForm } from 'react-hook-form'
import { useAlert } from 'react-alert'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AddIcon from '@mui/icons-material/Add'
// import CircularProgress from '@material-ui/core/CircularProgress'
// import Autocomplete from '@material-ui/lab/Autocomplete'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { useNavigate } from "react-router-dom";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import moment from 'moment'
import Moment from 'moment'
import DialogTitle from '@mui/material/DialogTitle';
import Headroom from "react-headroom";
import AgendaRegistration from "./AgendaRegistration";
import Draggable from 'react-draggable'
import { Resizable } from 're-resizable'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import Modal from '@mui/material/Modal'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Backdrop } from '@mui/material';


import Alert from '@mui/material/Alert'




const AppointmentCreate = props => {
  // console.log('🚀 ~ file: AppointmentCreate.js ~ line 162 ~ Appointment ~ Appointment', props.providerID)
  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const fields = [
    "id",
    "userId",
    "appointmentType",
    "appointmentDate",
    "startTime",
    "endTime",
    "locationId",
    "appointmentStatus",
    "appointmentReason",
    "duration",
    "practiceId",
    "therapistId",
    "supervisorId"
  ];
  const defValues = {
    id: 0,
    userId: 0,
    appointmentType: "",
    appointmentDate: "",
    startTime: "",
    endTime: "",
    locationId: 0,
    appointmentStatus: "",
    appointmentReason: "",
    duration: 0,
    practiceId: 0,
    supervisorId: 0,
    therapistId: 0,

  };
  const username = localStorage.getItem('UserName')
  const Userid = localStorage.getItem('userid')



  const navigate = useNavigate();
  const alert = useAlert();

  const validationSchema = Yup.object({
    supervisorId: Yup.string().required("Supervisor is required"),
    therapistId: Yup.string().required("Therapist is required"),
    duration: Yup.number()
      .typeError("Duration is required")
      .required("Duration is required")
      .positive("Duration must be greater than zero") // Ensure duration is positive
      .integer("Duration is required"),

    // locationName: Yup.string().required("Location Name is required"),

    // defaultPracticeId: Yup.array().required("Practice Name is required")
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const [submitting, setSubmitting] = useState(false);

  const [SuperviseID, setSuperviseID] = useState(props.SuperviseID);
  const [TherapistID, setTherapistID] = useState(props.TherapistID);
  console.log('SuperviseID---', SuperviseID);
  console.log('TherapistID----', TherapistID);

  const [SuperviseIDmodal, setSuperviseIDmodal] = useState("");
  const [appointmentDate, setAppointmentDate] = React.useState(Moment().format('YYYY-MM-DD'));
  const startDateRangeFormat = new Date(appointmentDate).toISOString().split('T')[0]; // Extract only the date part in YYYY-MM-DD format

  // Check if props.DateAppointment exists; if true, format it to 'YYYY-MM-DD', else use startDateRangeFormat
  const DateAppointment = props.DateAppointment
    ? new Date(props.DateAppointment).toISOString().split('T')[0]
    : startDateRangeFormat;

  const [sTime, setSTime] = useState(props.sTime);
  const [eTime, setETime] = useState(props.eTime);


  console.log('Endtime', appointmentDate)
  console.log('EndtimeDateAppointment', DateAppointment)
  console.log('EndtimestartDateRangeFormat', startDateRangeFormat)

  console.log('EndtimeDateAppointment11', props.DateAppointment)


  console.log('Endtime1', startDateRangeFormat)

  const [appid, setappid] = useState(props.appid !== undefined ? props.appid : 0)

  const [SuperviseList, setSuperviseList] = useState([]);
  const [TherapistList, setTherapistList] = useState([]);
  const startTime = sTime
  const endTime = eTime
  useEffect(() => {
    duration(startTime, endTime);

    // Pass the state variables as arguments
  }, [endTime, startTime]);
  const duration = (startTime, endTime) => {
    console.log("just checking duration wala Fn call ho raha hai ", endTime);

    // Make sure to use startTime instead of sTime in the next line
    const startTimeDuration = moment(`01/01/2022 ${startTime}`);
    const endTimeDuration = moment(`01/01/2022 ${endTime}`);

    // Assuming setValue function is defined somewhere in your code
    const duration = endTimeDuration.diff(startTimeDuration, "minutes", true);
    setValue("duration", duration);
    console.log("Duration:", duration);
  };
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Supervisor'}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.userName,
            value: field.id
          });
        });
        setSuperviseList(prac);
      })
      .catch(error => { });

  }, []);
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.userName,
            value: field.id
          });
        });
        setTherapistList(prac);


      })
      .catch(error => { });

  }, []);
  useEffect(() => {
    if (appid > 0) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/Appointment/getAppointmentById?id=${appid}`, {
          headers
        })

        .then(response => {
          fields.forEach(field => {
            setValue(field, response[field]);
            console.log("response.data: ", response);
            var dateYMD = Moment(response.appointmentDate).format('YYYY-MM-DD')
            setAppointmentDate(dateYMD)
            var dateYMD = Moment(response.startTime).format("HH:mm:ss")
            setSTime(dateYMD)
            var dateYMD = Moment(response.endTime).format("HH:mm:ss")
            setETime(dateYMD)
            setValue('therapistId', response.therapistId)
            setValue('supervisorId', response.supervisorId)
            setTherapistID(response.therapistId)
            setSuperviseID(response.supervisorId)

          });



        })
        .catch(error => { });
    }
  }, [appid]);

  const [refresh, setRefresh] = useState(false)


  const applyRefresh = () => {
    setRefresh(!refresh);
  };
  const [TherapistError, setTherapistError] = useState(false)
  const [SuperVisorError, setSuperVisorError] = useState(false)
  const onSubmit = data => {
    console.log('data', data)
    let postData = data;
    postData.startTime = sTime;
    postData.endTime = eTime;
    postData.appointmentDate = DateAppointment ? DateAppointment : startDateRangeFormat;
    postData.therapistId = TherapistID;
    postData.supervisorId = SuperviseID

    setTherapistError(false);
    setSuperVisorError(false);
    if (!SuperviseID) {
      setSuperVisorError(true)
      return;
    }
    if (!TherapistID) {
      setTherapistError(true)
      return;
    }
    setSubmitting(true);
  
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Appointment/AddAppointment`,
        postData,
        { headers }
      )
      .then(response => {
        props.CloseModal()
        if (typeof props.applyRefresh === 'function') {
          props.applyRefresh()
        }


        setSubmitting(false);
        alert.success("Record saved successfully.", {
          type: "success",
          onClose: () => {
            props.CloseModal()
            // navigate("/TrackHours");
          }
        });
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
  const [openAlert, setOpenAlert] = useState(false);
  const handleClickOpen = () => {

    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);

  };

  //Modal
  const handleDeleteById = () => {
    const postData = {

      id: appid,
      deleted: true,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Appointment/AddAppointment`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        setSubmitting(false);
        setOpenAlert(false);
        alert.success("Deleted Record successfully.", {
          type: "success",
          onClose: () => {
            props.CloseModal()
            // navigate("/TrackHours");
          }
        });

      })
      .catch((error) => {
        console.error(
          "There was an error!",
          error
        );
      });
  };


  const [value, setvalue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  //Minimize Code End


  // console.log("Props my patient id is :", props)
  const { patientId } = props
  const [state, setState] = useState({ width: 320, height: 200 })
  const { width, height } = useState(windowSize)
  const [open, setOpen] = useState(false)
  const [minimized, setMinimized] = useState(false)
  const [openn, setOpenn] = useState(false)
  const [roomId, setRoomId] = useState(null);
  const [doc, setDoc] = useState()
  // const urlParams = setDoc(window.location.href);
  console.log('doc', doc)

  // useEffect(() => {

  //   const url = new URL(doc);
  //   const roomIdFromUrl = url.searchParams.get('roomID'); 

  //   setRoomId(roomIdFromUrl);
  // }, [doc]);
  console.log('roomId', roomId)
  const handleopen = () => {
    setOpenn(true)
    setDoc(`https://dttmeetingroom.devforhealth.com/dttroomtestappshami.html?roomID=${TherapistID}`)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Users/updateRoomofSpecificTherapist?therapistId=${TherapistID}&videoLink=${TherapistID}`,
        null,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.id) {
          console.log(response)
        } else {
          //   setApiError(response.data)
        }
      })
      .catch((error) => { })
  }

  const handleClose = () => {
    setOpenn(false)

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Users/meetingFinishedBySupervisor?therapistId=${TherapistID}`,
        null,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.id) {
          console.log(response)
        } else {
          //   setApiError(response.data)
        }
      })
      .catch((error) => { })
  }

  const handleMinimize = () => {
    setMinimized(true)
  }

  const styleDetailsPdf = {
    // width: '80%',
    // height: '85%',
    width: state.width,
    height: state.height,
    bgcolor: '#F0FFFF',
    position: 'absolute',
    backgroundColor: 'white',
    top: '94px',
    // left: '267px',
    // bottom: '100px',
    right: '100px',
    margin: 0,
    marginTop: 0,
    paddingBottom: '10px',
    padding: '0px',
    // overflow: 'auto',
    zIndex: '10',
    // tabIndex: "-1",
  }

  const minimizedStyle = {
    width: '200px',
    height: '100px',
    position: 'fixed',
    bottom: '20px',
    right: '10px',
    zIndex: '11',
    transition: 'all 0.3s ease',
  }

  //Resizeable Code

  const handleResizeStop = (e, direction, ref, d) => {
    var oldState = state

    setState({
      width: oldState.width + d.width,
      height: oldState.height + d.height,
    })
  }

  const resizableStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    outline: 'black',
    border: '1px solid black',
    padding: '16px',
    position: 'absolute',
  }
  const UserType = localStorage.getItem("User_Type")
  const UserName = localStorage.getItem("UserName")
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Dialog
          open={openAlert}
          onClose={handleCloseAlert}
          aria-labelledby="responsive-dialog-title"

          BackdropComponent={Backdrop}
          BackdropProps={{
            invisible: true,
          }}
        >
          <DialogContent>
            <DialogContentText style={{ color: 'black' }}>
              Do you want to delete the record?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteById} style={{ background: 'red', color: 'white' }} autoFocus>
              Yes
            </Button>
            <Button autoFocus onClick={handleCloseAlert} style={{ color: 'white', background: '#3085d6' }}>
              No
            </Button>

          </DialogActions>
        </Dialog>

        {minimized ? (
          ''
        ) : (
          <Modal
            open={openn}
            closeOnEscape={false}
            closeOnClickOutside={false}
            onClose={() => { }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box>
              <Grid xs={8}>
                <Box style={{ cursor: 'move' }}>
                  <Draggable
                    bounds={{ left: -800, top: -100, right: 400, bottom: 500 }}
                  >
                    <Resizable
                      defaultSize={{ width: '80%', height: 600 }}
                      style={styleDetailsPdf}
                      onResizeStop={handleResizeStop}
                    >
                      <Box sx={{ resizableStyle }}>
                        {/* <div style = {{backgroundColor : 'Aqua'}} className="modal-title-bar"> */}

                        <Grid container style={{ backgroundColor: '#356f60' }} >

                          <Grid item xs={9}></Grid>
                          <Grid item xs={3}>
                            <Button sx={{ mb: 1 }} className="Button" onClick={handleClose} variant='contained'
                              style={{
                                fontSize: '14px', fontWeight: 'bold', float: 'right', marginTop: '10px', marginRight: '25px',
                                marginBottom: '5px',
                                width: '80px',
                                height: '35px',
                              }}>
                              <CancelOutlinedIcon
                                fontSize='small'
                                style={{
                                  color: 'red',
                                  paddingRight: '4px',
                                  paddingBottom: '2px'
                                }}
                              ></CancelOutlinedIcon>
                              Close
                            </Button>

                            <Button sx={{ mb: 1 }} className="Button" onClick={handleMinimize} variant='contained'
                              style={{
                                fontSize: '14px', fontWeight: 'bold', float: 'right', marginTop: '10px',
                                marginBottom: '5px',
                                marginRight: '13px',
                                width: '100px',
                                height: '35px',
                              }}>
                              <CloseFullscreenIcon
                                fontSize='small'
                                style={{
                                  color: 'red',
                                  paddingRight: '4px',
                                  paddingBottom: '2px'
                                }}
                              ></CloseFullscreenIcon>
                              Minimize
                            </Button>



                          </Grid>
                        </Grid>

                        {/* </div> */}

                        {openn ? (
                          <Grid
                            container
                            columnSpacing={1}
                            alignContent="center"
                            justifyContent="center"
                          >
                            <div
                              id="iframe_container"
                              style={{
                                position: 'absolute',
                                top: '3%',
                                // top: '15%',
                                bottom: '0%',
                                left: '0%',
                                right: '0%',
                                marginTop: '10px',
                                // marginBottom:"10px",
                              }}
                            >
                              <iframe
                                name="ifr"
                                // scrolling="yes"
                                fullWidth
                                style={{
                                  position: 'absolute',
                                  height: '100%',
                                  width: '100%',
                                }}
                                src={`${doc}`}
                                frameBorder="0"
                                allow="microphone; camera"
                                allowFullScreen
                              />
                            </div>
                          </Grid>
                        ) : null}
                      </Box>
                    </Resizable>
                  </Draggable>
                </Box>
              </Grid>
            </Box>
          </Modal>
        )}
        <Grid item xs={12} sm={12} xl={12} >
          <Headroom >
            <Grid
              container
              style={{ backgroundColor: '#356F60', position: 'sticky' }}
            >
              <Grid item xs={12} sm={12} xl={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'space-between',
                    height: '2rem'
                  }}
                >
                  <DialogTitle
                    style={{
                      flex: 1,
                      // textAlign: 'left',
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: 'bold',
                        // textAlign: 'left',
                        marginTop: '-10px',
                        color: '#fff',
                        textDecoration: 'underline',
                        fontSize: '1rem'

                      }}
                    >
                      Appointment Create

                    </h1>
                  </DialogTitle>
                  <CancelOutlinedIcon
                    onClick={props.CloseModal}
                    fontSize="large"
                    color="red"
                    style={{
                      color: 'red',
                      paddingRight: '5px',
                      cursor: 'pointer',
                      float: 'right'
                      // marginLeft: 'auto',
                      // marginRight: '1.5rem'
                    }}
                  ></CancelOutlinedIcon>
                </div>
              </Grid>
            </Grid>
          </Headroom>
        </Grid>
        <Grid item xs={12} sm={12} xl={12}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="lab API tabs example"
              >
                <Tab value={0} label="Supervision Request " />
                {appid > 0 ? (
                  <Tab value={1} label="Agenda" />
                ) : (
                  <></>
                )}

                {/* <Tab value={1} label="Agenda" /> */}

              </TabList>
            </Box>
            <TabPanel value={0}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6} xl={6}>
                  <Typography style={{ fontSize: '14px' }}>
                    Supervisor
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <Typography style={{ fontSize: '14px' }}>
                    Therapist
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <Controller
                    name="supervisorId"
                    {...register("supervisorId")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={SuperviseList.filter(function (option) {
                          console.log('SuperviseID id in filed ', SuperviseID)
                          return (
                            option.value == SuperviseID
                          );
                        })}
                        isDisabled={UserType === "Supervisor"}
                        onChange={e => {
                          setSuperviseID(e.value)
                          setValue('supervisorId', value != null ? value.e : '')
                          setValue("supervisorId", e.value);
                          setSuperVisorError(false)

                        }}
                        options={SuperviseList}
                      />

                    }
                  />
    {SuperVisorError && (
                    <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px' }}>
                      Please Select Supervisor
                    </Typography>
                  )}

                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <Controller
                    name="therapistId"
                    {...register("therapistId")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        isDisabled={UserType === "Therapist"}
                        value={TherapistList.filter(function (options) {
                          console.log('TherapistID id in filed ', TherapistID)
                          return (
                            options.value == TherapistID
                          );
                        })}
                        onChange={e => {
                          setTherapistID(e.value)
                          setValue('therapistId', value != null ? value.e : '')
                          setValue("therapistId", e.value);
                          setTherapistError(false)


                        }}
                        options={TherapistList}


                      />

                    }
                  />

                  {TherapistError && (
                    <Typography style={{ color: 'red', fontSize: '12px', marginLeft: '5px' }}>
                      Please Select Therapist
                    </Typography>
                  )}
                </Grid>


                <Grid item xs={6} sm={6} xl={6}>
                  <Typography style={{ fontSize: '14px' }}>
                    Start Time
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <Typography style={{ fontSize: '14px' }}>
                    End Time
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <Controller
                    name="startTime"
                    {...register("startTime")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        type="time"
                        size="small"
                        {...field}
                        fullWidth
                        // value={props.sTime}
                        value={sTime ? sTime : ''}
                        onChange={e => {
                          setValue('startTime', e.target.value)
                          setSTime(e.target.value)
                        }}


                      />}
                  />


                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <Controller
                    name="endTime"
                    // {...register("endTime")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        type="time"
                        size="small"
                        {...field}
                        fullWidth
                        // value={props.eTime}
                        value={eTime ? eTime : ''}
                        onChange={e => {
                          setValue('endTime', e.target.value)
                          setETime(e.target.value)
                        }}
                      />}
                  />

                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <Typography style={{ fontSize: '14px' }}>
                    Date
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <Typography style={{ fontSize: '14px' }}>
                    Reason
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>

                  <Controller
                    name="appointmentDate"
                    control={control}
                    render={({ field }) =>
                      <TextField
                        type="date"
                        size="small"
                        {...field}
                        fullWidth
                        value={DateAppointment} // Conditional value based on props.DateAppointment
                        onChange={e => {
                          setValue('appointmentDate', e.target.value);
                          setAppointmentDate(e.target.value);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <Controller
                    name="appointmentReason"
                    {...register("appointmentReason")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        type="text"
                        size="small"
                        placeholder="please enter reason"
                        {...field}
                        fullWidth

                      />}
                  />

                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                  <Typography style={{ fontSize: '14px' }}>
                    Duration
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} xl={6}>
                  <Controller
                    name="duration"
                    {...register("duration")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        type="text"
                        size="small"
                        placeholder="please enter duration"
                        {...field}
                        fullWidth
                        inputProps={
                          { readOnly: true, }
                        }
                        error={!!errors.duration}
                        helperText={errors.duration ? errors.duration.message : ""}
                      // value={duration}
                      // onChange={e => {
                      //   setValue("duration", e.target.value);
                      // }}

                      // value={Relationshipcontant}
                      // onChange={ev => {
                      //   console.log('ee:', ev.target.value)
                      //  setRelationshipcontant(ev.target.value)

                      //   }
                      // }

                      />}
                  />

                </Grid>

                <Grid item xs={1} sm={1} xl={1}>
                  <Typography style={{ fontSize: '14px' }}>
                    minutes
                  </Typography>
                </Grid>
                <Grid item xs={5} sm={5} xl={5}>
                  {minimized ? (
                    <div onClick={() => setMinimized(false)}>

                      <Button
                        className="Button"
                        variant="contained"
                        style={{
                          marginTop: '-3px',
                          marginBottom: '30px',
                          float: 'right',
                          marginLeft: 'auto',
                          width: '90px',
                          height: '30px',
                          // backgroundColor: '#FCBE13',
                          color: 'white',
                          fontSize: '14px',
                          cursor: 'pointer',
                        }}
                      >
                        Maximize
                      </Button>
                    </div>
                  ) : (
                    <>
                      {appid > 0 && UserType !== "Therapist" ? (<>

                        <Button sx={{ mb: 1 }} className="Button" onClick={handleopen} variant='contained' style={{ fontSize: '14px', fontWeight: 'bold', float: 'right', }}>
                          <VideoCallIcon
                            fontSize='small'
                            style={{
                              color: 'white',
                              paddingRight: '4px',
                              paddingBottom: '2px'
                            }}
                          ></VideoCallIcon>
                          Call
                        </Button>
                      </>) : ("")}

                    </>

                  )}

                </Grid>
                {/* <Grid item xs={2} sm={2} xl={2}></Grid> */}
                <Grid item xs={4} lg={4} style={{ marginTop: '10px' }}>
                  <Button
                    className="Button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={submitting}
                    fullWidth
                    style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item xs={4} lg={4} style={{ marginTop: '10px' }}>
                  <Button
                    className="Button"
                    onClick={props.CloseModal}
                    disabled={submitting}
                    fullWidth
                    style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}

                  >

                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={4} lg={4} style={{ marginTop: '10px' }}>
                  {appid > 0 ? (<Button
                    className="Button"
                    onClick={handleClickOpen}
                    fullWidth
                    style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}

                  >
                    <DeleteIcon

                      color="black"
                      style={{
                        marginRight: '5px',
                        float: 'right',
                        marginTop: '5px',
                        color: 'red',
                        cursor: 'pointer',
                        zIndex: 250000,
                      }}
                    />
                    Delete
                  </Button>) : ''}


                  {/* <Button onClick={handleClickOpen }>
       <DeleteIcon
       
         color="black"
         style={{
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
          cursor: 'pointer',
          zIndex: 250000,
        }}
       />
     </Button> */}

                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={1}>
              <AgendaRegistration callingFrom={'dashboard'} uid={appid} handleCloseprofile={props.CloseModal} TherapistID={TherapistID} sTime={sTime} eTime={eTime} ></AgendaRegistration>
              <ToastContainer
                position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"

              />
            </TabPanel>
          </TabContext>

        </Grid>

      </Grid>
    </React.Fragment>
  )
}
export default AppointmentCreate;
