import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Moment from "moment";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Headroom from "react-headroom";
import DialogTitle from "@mui/material/DialogTitle";
import ReactSelect from "react-select";
import NewPaymentCardSetup from "./PaymentMethod/NewPaymentCardSetup";

import {
  InputLabel,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { InputAdornment } from '@mui/material';
import { Box, Button, Typography, Grid, TextField } from "@mui/material";
import { useAlert } from "react-alert";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
const ProcessPayment = (props) => {
  // API Authanticatiion
  const token = localStorage.getItem("Token");
  const auth = `Bearer ${token}`;
  const headers = {
    Authorization: auth,
  };

  const username = localStorage.getItem("UserName");
  const [paymentCardId, setPaymentCardId] = useState("");


  const fields = [
    "id",
    "chequeNumber"
  ];

  const defValues = {
    id:0,
    chequeNumber: ''
  };

  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const [Continue, setContinue] = useState(false);
  const [method , setmethod] = useState('Cash')
  const [Phone, setPhone] = useState("");
  const [num, setNum] = React.useState();
  const [userType, setUserType]= useState("");
  const [requestStatus, setRequestStatus]= useState("Pending");
  const [submitForm, setSubmitForm]=useState(false);

  const [displayedDigits, setDisplayedDigits] = useState('');
  const navigate = useNavigate();
  
  const location = useLocation();
  const PaymentId = location.state.paymentId;
  const PaymentMethod=location.state.payMethod;


  const validationSchema = Yup.object({
    
  chequeNumber: Yup.number().required("Enter Cheque Number to proceed")

   
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });
  const [approve, setapprove] = useState(false);

  const [newCardSetup, setNewCardSetup] = useState(false);

  const onSubmit = data => {  

   data.id=PaymentId; 
    let postData = data;
    setSubmitting(true);
    axios
    .post(
      `${process.env.REACT_APP_API_URL}/Payment/processChequePaymentRequest`,
      postData,
      { headers }
    )
    .then((response) => {
      alert.success("Cheque Payment processed successfully ", {
        type: "success",
        onClose: () => {
          navigate("/PaymentMethod");
        },
      });
    })
    .catch((error) => {
      setSubmitting(false);
      alert.error(`Error ${error.message}`, {
        type: "error",
      });
    });  
    
   
  };


  function handleCancel() {
    navigate('/TakePayment', { state: { paymentId: PaymentId } })
  }

 
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-40%, -50%)",
    width: 1000,
    maxHeight: '60vh',
    // height: 500,
    bgcolor: "white",
    border: "2px solid #377562",
    boxShadow: 24,
    // overflow: "hidden",
    // p: 4,
    borderRadius: "8px",
    overflow: "auto",
    /* WebKit (Chrome, Safari) */
    "&::-webkit-scrollbar": {
      width: 8 /* Width of vertical scrollbar */,
      height: 10,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#377562",
      borderRadius: 10,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    /* Adjust the width for the horizontal scrollbar */
    "&::-webkit-scrollbar-thumb:horizontal": {
      width: 4 /* Width of horizontal scrollbar */,
    },
    /* For Firefox */
    "&*": {
      scrollbarWidth: "thin",
    },
  };

  const [paymentCardList, setPaymentCardList] = useState([]);

 useEffect(()=>{
   setUserType(localStorage.getItem("User_Type"));
 },[])
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/Payment/getTherapistPaymentCards`,
        {
          headers,
        }
      )
      .then((response) => {
        var prac = [];
        prac.push({
          label: "New Card",
          value: -1
        });
        response.forEach((field) => {
          if(field.customerPaymentId)
          prac.push({
            label: field.nameCard +" "+ field.cardNumber +" "+field.expiration,
            value: field.customerPaymentId,
          });
        });
        setPaymentCardList(prac);
      })
      .catch((error) => {});
  }, []); 

  useEffect(()=>{
    if(paymentCardId==-1)
      setNewCardSetup(true)
    else
    setNewCardSetup(false)

  },[paymentCardId])


  const processCreditCardPaymentHandler=(data)=>{

   if(paymentCardId!='-1'){

    let postData={
      'id':PaymentId,
      'paymentMethod':PaymentMethod,
      'customerPaymentId':paymentCardId
    }


    axios
    .post(
      `${process.env.REACT_APP_API_URL}/Payment/processDefaultCreditCardPaymentRequest`,
      postData,
      { headers }
    )
    .then((response) => {
      alert.success("Credit Card Payment processed successfully ", {
        type: "success",
        onClose: () => {
          navigate("/PaymentMethod");
        },
      });
    })
    .catch((error) => {
      setSubmitting(false);
      alert.error(`Error ${error.message}`, {
        type: "error",
      });
    });  
   }

  }

 
  return (
    <React.Fragment>
      
      <Grid container>
        <Box sx={style}>
          <Grid container spacing={1} style={{ marginBottom: "20px" }}>
           
            <Grid
                  container
                  style={{
                    backgroundColor: "#356F60",
                    position: "sticky",
                    height: "50px",
                  }}
                > 
                  <Grid item xs={8}>
                  <Typography style={{ fontSize: "20px", fontWeight: "bold", color:"white", marginTop:"22px", marginLeft:"20px" }}>
                {userType=="Therapist"?"Process Payment":"Request New Payment"}
              </Typography>
              </Grid>
              <Grid item xs={4}>
                    <CancelOutlinedIcon
                      onClick={handleCancel}
                      fontSize="large"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        cursor: "pointer",
                        float: "right",
                      }}
                    ></CancelOutlinedIcon>
                  </Grid>
                </Grid>
                {PaymentMethod=="check"?(
                <>
                  <Grid container spacing={1}>
                    
                  <Grid item xs={4}>
               <Typography
                style={{ fontSize: "13px", fontWeight: "bold", color: "black"  , marginTop : '30px', marginLeft:"20px"}}
              >
                Cheque Number
              </Typography>
                  </Grid>

                  <Grid item xs={7} style={{marginTop:'10px'}}>

                  <Controller
                  name='chequeNumber'
                  {...register('chequeNumber')}
                  control={control}
                  render={({ field }) => (
                    <TextField
                    type='number'
                    size="small"
                    label="Cheque Number"
                    placeholder="please enter cheque Number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                     {...field}
                      // onChange={event =>  event.target.value}
                      fullWidth
                      error={errors.chequeNumber}
                    />
                  )}
                />
              </Grid>
                </Grid>
                </>):(

                <>
               <Grid container spacing={1}>
               <Grid item xs={4}>
               <Typography
                style={{ fontSize: "13px", fontWeight: "bold", color: "black"  , marginTop : '20px', marginLeft:"20px"}}
              >
                Payment Card
              </Typography>
                  </Grid>

                  <Grid item xs={7} style={{ marginTop:"20px" }}>
                    <Controller
                      name="userId"
                      control={control}
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          fullWidth
                          value={paymentCardList.find(
                            (option) => option.value === username
                          )}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value);
                            setPaymentCardId(selectedOption.value);

                          }}
                          options={paymentCardList}
                          error={errors.userId}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 1000,
                            }),
                          }}
                        />
                      )}
                    />
               

                </Grid>
               </Grid>

               <Grid item xs={12} style={{marginLeft:"-20px", marginTop:"20px"}}>
                ${newCardSetup?(
                <NewPaymentCardSetup />
                ):(<></>)}
                 {/* <NewPaymentCardSetup /> */}
                
                </Grid>     
                </>
               )}
              
           <Grid item xs={12} style={{marginLeft:"-20px", marginTop:"20px"}}>
            {PaymentMethod=="check"?(<>
                <Button
                    className="Button"
                    onClick={handleSubmit(onSubmit)}
                    style={{
                      borderRadius: "10px",
                      color: "white",
                      height: "40px",
                      width : '110px',
                      borderColor: "#EA7772",
                      fontSize: "14px",
                      fontWeight: "bold",
                      float : 'right'
                    }}
                    type="button"
                  >
                    Pay
                  </Button>
               </>):(<>
                  <Button
                    className="Button"
                    onClick={processCreditCardPaymentHandler}
                    hidden={paymentCardId=='-1'}
                    style={{
                      borderRadius: "10px",
                      color: "white",
                      height: "40px",
                      width : '110px',
                      borderColor: "#EA7772",
                      fontSize: "14px",
                      fontWeight: "bold",
                      float : 'right'
                    }}
                    type="submit"
                  >
                    Pay
                  </Button></>)

            }
           
            </Grid>
        
          </Grid>
        </Box>
      </Grid>
    </React.Fragment>
  );
};
export default ProcessPayment;
