import { useState, useEffect, startTransition } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';


const AgendaRegistration = props => {
  const fields = [
    "id",
    "userId",
    "practiceId",
    "modality",
    "userType",
    "taskPerformed",
    "discussionIC",
    "userContent",
    "dateTime",
    "startTime",
    "endTime",
    "goals",
    "therapistsId",
    
    
  ];
  const defValues = {
    id: 0,
    userId: 0,
    practiceId: 0,
    therapistsId: 0,
    dateTime: "",
    modality: "",
    userType: "",
    taskPerformed: "",
    discussionIC: "",
    userContent: "",
    startTime: "",
    endTime: "",
    goals: "",
  }
  const [Date, setDate] = React.useState('')
  const [StartTime, setStartTime] = React.useState(props.sTime)
  console.log('testtime',StartTime)
  const [EndTime, setEndTime] = React.useState(props.eTime)

  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  // const _userId = location.state.uid;
  const [_userId, set_userId] = useState(props.callingFrom == 'dashboard' ? props.uid : props.uid)

  const [approve, setapprove] = useState(false);
  const [Approval, setapprovel] = useState(false);

  console.log('_userId', _userId)

  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const Usertype = localStorage.getItem('User_Type')
  const UserName = localStorage.getItem('UserName')
  const validationSchema = Yup.object({
    dateTime: Yup.string().required("Date is required"),

  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  
  const onSubmit = data => {
    let postData = data;
    setSubmitting(true);
    postData.appointmentId = _userId
    postData.dateTime = Date;
    postData.startTime = StartTime;
    postData.endTime = EndTime;
    postData.therapistsId = props.TherapistID
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/UserNotes/AddUserNote`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);
          toast.success('Record saved successfully', {
            position: "bottom-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",

            onClose: () => {
              if (props.callingFrom == 'dashboard') {
                props.handleCloseprofile()
              }
              else {
                props.handleClose()
              }

            }

          });
          // alert.success("Record saved successfully.", {
          //   type: "success",
          //   onClose: () => {
          //     if(props.callingFrom == 'dashboard')
          //     {
          //       props.handleCloseprofile()
          //     }
          //     navigate("/Agendas");
          //   }
          // });
        } else if (response.length > 0) {

          setSubmitting(false);
          return;
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
 
  const onSubmitagenda = data => {
    let postData = data;
    setSubmitting(true);
    if (Usertype == 'Therapist') {
      postData.pendingApproval = true
      postData.appointmentId = _userId
      postData.therapistsId = props.TherapistID
      postData.dateTime = Date;
      postData.startTime = StartTime;
      postData.endTime = EndTime;
      // postData.therapistsId =TherapistID

    }
    else {
      postData.approve = true;
      postData.approvedBy = UserName;
      postData.approvedDateTime =   Moment().format('YYYY-MM-DD HH:mm');
      postData.appointmentId = _userId
      postData.therapistsId = props.TherapistID
      postData.dateTime = Date;
      postData.startTime = StartTime;
      postData.endTime = EndTime;
   
      
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/UserNotes/AddUserNote`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);
          toast.success('Record saved successfully', {
            position: "bottom-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",

            onClose: () => {
              if (props.callingFrom == 'dashboard') {
                props.handleCloseprofile()
              }
              else {
                props.handleClose()
              }
            }

          });
          // alert.success("Record saved successfully.", {
          //   type: "success",
          //   onClose: () => {
          //     if(props.callingFrom == 'dashboard')
          //     {
          //       props.handleCloseprofile()
          //     }
          //     navigate("/Agendas");

          //   }
          // });
        } else if (response.length > 0) {

          setSubmitting(false);
          return;
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  useEffect(() => {
    if (props.callingFrom == 'dashboard') {
      axios
        .get(`${process.env.REACT_APP_API_URL}/UserNotes/getUserNoteByAppointmnetId?AppointmentId=${_userId}`, {
          headers
        })
        .then(response => {
          console.log("response.data: ", response);
          fields.forEach(field => {
            setValue(field, response[field]);
            if (response.data) {
              setIsDataLoaded(true); 
            }
            setapprove(response.approve)
            setapprovel(response.pendingApproval)
          //   var dateYMD4 = Moment(response.startTime).format("HH:mm:ss")
          // setStartTime(dateYMD4)
          // var dateYMD5 = Moment(response.endTime).format("HH:mm:ss")
          // setEndTime(dateYMD5)
            if (response.dateTime) {
              var dateYMD = Moment(response.dateTime).format('YYYY-MM-DD')
              setValue('dateTime', dateYMD);
              setDate(dateYMD);
            } else {
            var currentDateYMD = Moment().format('YYYY-MM-DD');
            setValue('dateTime', currentDateYMD);
            setDate(currentDateYMD);

            }
       

          });
        })

        .catch(error => { });
    }
    else {
      axios
        .get(`${process.env.REACT_APP_API_URL}/UserNotes/getLocationById?userNoteId=${_userId}`, {
          headers
        })
        .then(response => {
          console.log("response.data: ", response);
          fields.forEach(field => {
            setValue(field, response[field]);
            var dateYMD = Moment(response.dateTime).format('YYYY-MM-DD')
                setValue('dateTime', dateYMD);
              setDate(dateYMD);
            setapprove(response.approve)

          });
        })
        .catch(error => { });
    }
  }, []);




  function handleCancel() {
    navigate("/Agendas");
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #EA7772',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
    overflow: 'auto',
    /* WebKit (Chrome, Safari) */
    '&::-webkit-scrollbar': {
      width: 8, /* Width of vertical scrollbar */
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#377562',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    /* Adjust the width for the horizontal scrollbar */
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4, /* Width of horizontal scrollbar */
    },
    /* For Firefox */
    '&*': {
      scrollbarWidth: 'thin',
    },
  }
  const style1 = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    // border: '2px solid #EA7772',
    // boxShadow: 24,
    overflow: 'hidden',
    // p: 4,
    // borderRadius: '8px',
    overflow: 'auto',
    /* WebKit (Chrome, Safari) */
    '&::-webkit-scrollbar': {
      width: 8, /* Width of vertical scrollbar */
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#377562',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    /* Adjust the width for the horizontal scrollbar */
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4, /* Width of horizontal scrollbar */
    },
    /* For Firefox */
    '&*': {
      scrollbarWidth: 'thin',
    },
  }
  const [TherapistID, setTherapistID] = useState("");
  const [TherapistList, setTherapistList] = useState([]);

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.userName,
            value: field.id
          });
        });
        setTherapistList(prac);

      })
      .catch(error => { });

  }, []);
  return (
    <React.Fragment>
      <Grid container>
        {props.callingFrom == 'dashboard' ? (<>
          <Box sx={style1} >

            <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
                <Typography style={{ fontSize: '14px' }}>
                  Date
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Controller
                  name="dateTime"
                  // {...register("dateTime")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      size="small"
                      type="date"
                      {...field}
                      fullWidth
                      value={Date}
                      onChange={e => {
                        setValue('dateTime', e.target.value)
                        setDate(e.target.value)
                      }}
                      error={errors.dateTime}
                    />}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>{errors.dateTime?.message}</p>
              </Grid>

              <Grid item xs={12} lg={12}>
                <Typography style={{ fontSize: '14px' }}>
                  Therapist
                </Typography>
              </Grid>


              <Grid item xs={12} lg={12}>
                <Controller
                  name="therapistsId"
                  {...register("therapistsId")}
                  control={control}
                  render={({ field }) => {
                    const selectedValue = TherapistList.find(option => option.value === props.TherapistID);
                    console.log("UserName:", props.TherapistID);
                    console.log("TherapistList:", TherapistList);
                    console.log("Selected Value:", selectedValue);

                    if (selectedValue) {
                      console.log("Match found:", selectedValue);
                    } else {
                      console.log("No match found");
                    }
                    return (
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={selectedValue}
                        isDisabled={true}
                        onChange={e => {
                          setValue("therapistsId", e.value);
                          setTherapistID(e.value);
                        }}
                        options={TherapistList}
                      />
                    );
                  }}
                />

              </Grid>


              {/* <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                Therapist
                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid> */}
          

              <Grid item xs={6} lg={6}>
                <Typography style={{ fontSize: '14px' }}>
                  Start Time
                </Typography>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Typography style={{ fontSize: '14px' }}>
                  End Time
                </Typography>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Controller
                  name="startTime"
                  // {...register("startTime")}
                  control={control}
                  render={({ field }) =>
                    <TextField
                      type="time"
                      size="small"
                      {...field}
                      fullWidth
                      value={StartTime ? StartTime : ''}
                      // value={field.value} 
                         onChange={e => {
                          setValue('startTime', e.target.value)

                          // field.onChange(e.target.value);
                          setStartTime(e.target.value); 
                          }}
                    />}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
              <Controller
                                            name="endTime"
                                            // {...register('endTime')}
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <TextField
                                                    {...field}
                                                    size="small"
                                                    type="time"
                                                    fullWidth
                                                    value={EndTime ? EndTime : ''}
                                                    // value={field.value} 
                                                    onChange={e => {
                                                      setValue('endTime', e.target.value)
                                                        // field.onChange(e.target.value);
                                                        setEndTime(e.target.value); 
                                                    }}

                                                />
                                            )}
                                        />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography style={{ fontSize: '14px' }}>
                  Activities Performed
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Controller
                  name='taskPerformed'
                  {...register('taskPerformed')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='enter activities'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        overflow: 'auto'
                      }}
                      {...field}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography style={{ fontSize: '14px' }}>
                  Issues/Concerns Discussed
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Controller
                  name='discussionIC'
                  {...register('discussionIC')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='enter issues'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        overflow: 'auto'
                      }}
                      {...field}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography style={{ fontSize: '14px' }}>
                  Specific Supervisor Content
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Controller
                  name='userContent'
                  {...register('userContent')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='enter supervisor content'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        overflow: 'auto'
                      }}
                      {...field}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography style={{ fontSize: '14px' }}>
                  Goals for Provisionally Licensed therapist
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Controller
                  name='goals'
                  {...register('goals')}
                  control={control}
                  render={({ field }) => (
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={5}
                      placeholder='enter goals'
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '2px solid #black',
                        height: '100px', // set a fixed height here
                        overflow: 'auto'
                      }}
                      {...field}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} lg={6}>
                <Button
                  className="Button"
                  onClick={handleSubmit(onSubmit)}
                  disabled={submitting || Approval }
                  fullWidth
                  style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                  type="submit"
                >
                  Save Agenda
                </Button>
              </Grid>
              {Usertype == 'Therapist' ? (<>
                {approve == true ? (<>
                  <Grid item xs={6} lg={6}>
                    <Button
                      className="Button"
                      onClick={handleSubmit(onSubmitagenda)}
                      disabled={submitting || Approval }

                      fullWidth
                      style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                      type="submit"
                    >

                      Approved
                      <TaskAltIcon
                        style={{
                          color: approve == true ?'green':'red',
                          // cursor: "not-allowed",
                        }}
                      >

                      </TaskAltIcon>
                    </Button>
                  </Grid>

                </>) : (<>
                  <Grid item xs={6} lg={6}>
                    <Button
                      className="Button"
                      onClick={handleSubmit(onSubmitagenda)}
                      disabled={submitting || Approval }
                      fullWidth
                      style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                      type="submit"
                    >
                      Send For Approval
                      <TaskAltIcon
                        style={{
                          color: Approval == true ?'green':'red',
                          // cursor: "not-allowed",
                        }}
                      >

                      </TaskAltIcon>
                    </Button>
                  </Grid>

                </>)}


              </>) : (
                <>
                {approve == true ?(<>
                  <Grid item xs={6} lg={6}>
                    <Button
                      className="Button"
                      onClick={handleSubmit(onSubmitagenda)}
                      disabled={submitting || Approval }
                      fullWidth
                      style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                      type="submit"
                    >
                      Approved
                      <TaskAltIcon
                        style={{
                          color: approve == true ?'green':'red',
                          // cursor: "not-allowed",
                        }}
                      >

                      </TaskAltIcon>
                    </Button>
                  </Grid></>):(

                  <>
                  <Grid item xs={6} lg={6}>
                    <Button
                      className="Button"
                      onClick={handleSubmit(onSubmitagenda)}
                      disabled={submitting}
                      fullWidth
                      style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                      type="submit"
                    >
                      <TaskAltIcon
                        style={{
                          color: approve == true ?'green':'red',
                          // cursor: "not-allowed",
                        }}
                      />
                      Approved

                    </Button>
                  </Grid>
  
                </>
                )}
                </>
               )}
              {/* <Grid item xs={12} lg={12} style={{ textAlign: 'center' }}>
                <Button

                  onClick={props.callingFrom == 'dashboard' ? props.handleCloseprofile : props.handleClose}

                  disabled={submitting}
                  fullWidth
                  style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", backgroundColor: '#377562', fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}

                >

                  View More Agendas
                </Button>

              </Grid> */}
            </Grid>



          </Box>

        </>) : (<>
          {/* <Box sx={style} > */}

          <Grid container spacing={1}>


            {/* <Grid item xs={12} sm={12} xl={12} > 
        <Headroom >
            <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            // textAlign: 'left',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Agenda Registration

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                          onClick={props.callingFrom == 'dashboard'?props.handleCloseprofile:handleCancel}

                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
              </Grid> */}

              {approve == true? 
            (<>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
            <Grid item xs={0.1} sm={0.1} xl={0.1}></Grid>
            <Grid item xs={11} sm={11} xl={11}>
              <Typography style={{ fontSize: '14px' }}>
                Date
              </Typography>
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={11} sm={11} xl={11} style={{ marginTop: '3px' }}>
              <Controller
                name="dateTime"
                // {...register("dateTime")}
                control={control}
                render={({ field }) =>
                  <TextField
                    size="small"
                    type="date"
                    {...field}
                    fullWidth
                    value={Date ? Date : ''}
                    InputProps={{
                      readOnly: true
                    }}
                    onChange={e => {
                      setValue('dateTime', e.target.value)
                      setDate(e.target.value)
                    }}
                    error={errors.dateTime}
                  />}
              />
              <p style={{ color: 'red', fontSize: '14px' }}>{errors.dateTime?.message}</p>
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={11} sm={11} xl={11}>
              <Typography style={{ fontSize: '14px' }}>
                Therapist
              </Typography>
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={11} sm={11} xl={11}>
              <Controller
                name="therapistsId"
                // {...register("therapistsId")}
                control={control}
                render={({ field }) =>
                  <ReactSelect
                    {...field}
                    fullWidth
                    value={TherapistList.filter(function (option) {
                      return (
                        option.value === getValues("therapistsId")
                      );
                    })}
                    onChange={e => {
                      setValue("therapistsId", e.value);
                      setTherapistID(e.value);


                    }}
                    isDisabled={true}
                    options={TherapistList}
                  />

                }
              />
            </Grid>
            </>
            ):(
              <>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
            <Grid item xs={0.1} sm={0.1} xl={0.1}></Grid>
            <Grid item xs={11} sm={11} xl={11}>
              <Typography style={{ fontSize: '14px' }}>
                Date
              </Typography>
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={11} sm={11} xl={11} style={{ marginTop: '3px' }}>
              <Controller
                name="dateTime"
                // {...register("dateTime")}
                control={control}
                render={({ field }) =>
                  <TextField
                    size="small"
                    type="date"
                    {...field}
                    fullWidth
                 
                    value={Date ? Date : ''}
                    onChange={e => {
                      setValue('dateTime', e.target.value)
                      setDate(e.target.value)
                    }}
                    error={errors.dateTime}
                  />}
              />
              <p style={{ color: 'red', fontSize: '14px' }}>{errors.dateTime?.message}</p>
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={11} sm={11} xl={11}>
              <Typography style={{ fontSize: '14px' }}>
                Therapist
              </Typography>
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={11} sm={11} xl={11}>
              <Controller
                name="therapistsId"
                // {...register("therapistsId")}
                control={control}
                render={({ field }) =>
                  <ReactSelect
                    {...field}
                    fullWidth
                    value={TherapistList.filter(function (option) {
                      return (
                        option.value === getValues("therapistsId")
                      );
                    })}
                    onChange={e => {
                      setValue("therapistsId", e.value);
                      setTherapistID(e.value);


                    }}
                    options={TherapistList}
                  />

                }
              />
            </Grid>
              </>
            )  
            }
           
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

        


            {approve == true ? (

              <>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={5.5} sm={5.5} xl={5.5} >
                  <Typography style={{ fontSize: '14px' }}>
                    Start Time
                  </Typography>
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}  >
                  <Typography style={{ fontSize: '14px' }}>
                    End Time
                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5} >
                  <Controller
                    name="startTime"
                    {...register("startTime")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        type="time"
                        size="small"

                        {...field}
                        fullWidth
                        InputProps={{
                          readOnly: true
                        }}

                      />}
                  />
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5} >
                  <Controller
                    name="endTime"
                    {...register("endTime")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        type="time"
                        size="small"
                        {...field}
                        fullWidth
                        InputProps={{
                          readOnly: true
                        }}
                      />}
                  />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Typography style={{ fontSize: '14px' }}>
                    Activities Performed
                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>


                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Controller
                    name='taskPerformed'
                    {...register('taskPerformed')}
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        aria-label='minimum height'
                        minRows={5}
                        placeholder='enter activities'
                        style={{
                          width: '100%',
                          borderRadius: '8px',
                          border: '2px solid #black',
                          height: '100px', // set a fixed height here
                          overflow: 'auto'
                        }}
                        readOnly={true}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Typography style={{ fontSize: '14px' }}>
                    Issues/Concerns Discussed
                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Controller
                    name='discussionIC'
                    {...register('discussionIC')}
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        aria-label='minimum height'
                        minRows={5}
                        placeholder='enter issues'
                        style={{
                          width: '100%',
                          borderRadius: '8px',
                          border: '2px solid #black',
                          height: '100px', // set a fixed height here
                          overflow: 'auto'
                        }}
                        readOnly={true}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Typography style={{ fontSize: '14px' }}>
                    Specific Supervisor Content
                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Controller
                    name='userContent'
                    {...register('userContent')}
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        aria-label='minimum height'
                        minRows={5}
                        placeholder='enter supervisor content'
                        style={{
                          width: '100%',
                          borderRadius: '8px',
                          border: '2px solid #black',
                          height: '100px', // set a fixed height here
                          overflow: 'auto'
                        }}
                        readOnly={true}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Typography style={{ fontSize: '14px' }}>
                    Goals for Provisionally Licensed therapist
                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Controller
                    name='goals'
                    {...register('goals')}
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        aria-label='minimum height'
                        minRows={5}
                        placeholder='enter goals'
                        style={{
                          width: '100%',
                          borderRadius: '8px',
                          border: '2px solid #black',
                          height: '100px', // set a fixed height here
                          overflow: 'auto'
                        }}
                        readOnly={true}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                {/* <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid> */}
              </>
            ) : (
              <>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={5.5} sm={5.5} xl={5.5} >
                  <Typography style={{ fontSize: '14px' }}>
                    Start Time
                  </Typography>
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}  >
                  <Typography style={{ fontSize: '14px' }}>
                    End Time
                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5} >
                  <Controller
                    name="startTime"
                    {...register("startTime")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        type="time"
                        size="small"
                        {...field}
                        fullWidth

                      />}
                  />
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5} >
                  <Controller
                    name="endTime"
                    {...register("endTime")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        type="time"
                        size="small"
                        {...field}
                        fullWidth

                      />}
                  />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Typography style={{ fontSize: '14px' }}>
                    Activities Performed
                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>


                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Controller
                    name='taskPerformed'
                    {...register('taskPerformed')}
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        aria-label='minimum height'
                        minRows={5}
                        placeholder='enter activities'
                        style={{
                          width: '100%',
                          borderRadius: '8px',
                          border: '2px solid #black',
                          height: '100px', // set a fixed height here
                          overflow: 'auto'
                        }}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Typography style={{ fontSize: '14px' }}>
                    Issues/Concerns Discussed
                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Controller
                    name='discussionIC'
                    {...register('discussionIC')}
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        aria-label='minimum height'
                        minRows={5}
                        placeholder='enter issues'
                        style={{
                          width: '100%',
                          borderRadius: '8px',
                          border: '2px solid #black',
                          height: '100px', // set a fixed height here
                          overflow: 'auto'
                        }}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Typography style={{ fontSize: '14px' }}>
                    Specific Supervisor Content
                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Controller
                    name='userContent'
                    {...register('userContent')}
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        aria-label='minimum height'
                        minRows={5}
                        placeholder='enter supervisor content'
                        style={{
                          width: '100%',
                          borderRadius: '8px',
                          border: '2px solid #black',
                          height: '100px', // set a fixed height here
                          overflow: 'auto'
                        }}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Typography style={{ fontSize: '14px' }}>
                    Goals for Provisionally Licensed therapist
                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                  <Controller
                    name='goals'
                    {...register('goals')}
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        aria-label='minimum height'
                        minRows={5}
                        placeholder='enter goals'
                        style={{
                          width: '100%',
                          borderRadius: '8px',
                          border: '2px solid #black',
                          height: '100px', // set a fixed height here
                          overflow: 'auto'
                        }}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

              </>
            )}
      
      <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
            <Grid item xs={5.5} sm={5.5} xl={5.5} >
              <Button
                className="Button"
                onClick={handleSubmit(onSubmit)}
                disabled={approve ? true : submitting}
                fullWidth
                style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                type="submit"
              >
                Save Agenda
              </Button>
            </Grid>
            {Usertype == 'Therapist'||Usertype== 'Supervisor' || Usertype== 'Admin'? (<>
              {approve == true ? (
                <>
                
                  <Grid item xs={5.5} sm={5.5} xl={5.5} >
                    <Button
                      className="Button"
                      // onClick={handleSubmit(onSubmitagenda)}
                      disabled={approve ? true : submitting}

                      fullWidth
                      style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                      type="submit"
                    >

                      Approved
                      <TaskAltIcon
                        style={{
                          color: "green",
                          // cursor: "not-allowed",
                        }}
                      >

                      </TaskAltIcon>
                    </Button>
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

                </>
              ) : (<>
                <Grid item xs={5.5} sm={5.5} xl={5.5} >
                  <Button
                    className="Button"
                    onClick={handleSubmit(onSubmitagenda)}
                    disabled={submitting}
                    fullWidth
                    style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                    type="submit"
                  >
                    {Usertype == 'Therapist' ? 'Submit for Approval' : 'Approve'}
                    <TaskAltIcon
                        style={{
                          color: "red",
                          // cursor: "not-allowed",
                        }}
                      />
                  </Button>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>


              </>)}


            </>) : (<>
              <Grid item xs={5.5} sm={5.5} xl={5.5} >
                <Button
                  className="Button"
                  onClick={handleSubmit(onSubmitagenda)}
                  disabled={submitting}
                  fullWidth
                  style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                  type="submit"
                >
                  Approved
                </Button>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

            </>)}

          </Grid>

        </>)}

      </Grid>
    </React.Fragment>
  );
};
export default AgendaRegistration;
