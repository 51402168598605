// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography, NativeSelect, Paper } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import moment from 'moment'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
const LeaveRequest = () => {
  document.title = "LeaveRequest | Maevisory";

  const [isLoading, setLoading] = useState(false)
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }

  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [refresh, setRefresh] = useState(false)
  function applyRefresh() {
    setRefresh(!refresh)
  }
  const navigate = useNavigate();

  const columns = [
    {
      field: 'leavePurpose',
      headerClassName: 'super-app-theme--header',
      headerName: 'Leave Purpose',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: cellValues => (
        <Button
          size='small'
          variant='text'
          color='info'
          style={{
            maxWidth: '100%',
            // wordWrap: 'break-word',
            // whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            // textOverflow: 'ellipsis',
            display: 'block',
          }}
          onClick={(event) => {
            openLeaveRequestRegistration(event, cellValues.row.id)
          }}
        >{`${cellValues.row.leavePurpose}`}</Button>
      )
    },

    {
      field: 'additionalInfo',
      headerClassName: 'super-app-theme--header',
      headerName: 'Additional Information',
      flex: 0.2
    },

    {
      field: 'leaveStartDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'StartDate',
      flex: 0.2,
    },
    {
      field: 'leaveEndDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'EndDate',
      flex: 0.2,
    },

    {
      field: 'createdDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'Activity time stamp ',
      flex: 0.23,
      renderCell: (params) => {
        return params.value ? moment(params.value).format('MM/DD/YYYY HH:mm') : '';
      },
    },
    {
      field: 'approveDateTime',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approved Date',
      flex: 0.3
    },
    {
      field: 'approvedBy',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approved By',
      flex: 0.19
    },
    {
      field: 'approved',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approve',
      flex: 0.12,
      renderCell: cellValues => {
        return cellValues.row.approved ? (
          <CheckIcon style={{ color: 'green' }} />
        ) : (
          <CloseIcon style={{ color: 'red' }} />
        )
      }
    },

    {
      field: 'isActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {

                      inactive: true,
                      id: cell.row.id
                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/LeaveRequest/addLeaveRequest`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }

  ]
  const [selectedValue, setSelectedValue] = useState('')
  const handleChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    applyRefresh(newValue)
    console.log("Selected value my kya ha : ", newValue)
  }
  const [rows, setRows] = useState(null)
  const UserType = localStorage.getItem("User_Type")
  const onFilterSearch = event => {
    if (UserType == 'Therapist') {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequest?requestString=${selectedValue}&Therpistid=${Userid}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
          setData(response)

        })
        .catch(error => { })

    } else {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequest?requestString=${selectedValue}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
          setData(response)

        })
        .catch(error => { })

    }

  }
  useEffect(() => {
    if (UserType == 'Therapist') {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequest?requestString=${selectedValue}&Therpistid=${Userid}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
          setData(response)

        })
        .catch(error => { })

    } else {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequest?requestString=${selectedValue}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
          setData(response)

        })
        .catch(error => { })

    }
  }, [refresh, selectedValue, UserType])
  const UserName = localStorage.getItem("UserName")
  const [Data, setData] = useState([])
  const Userid = localStorage.getItem('userid')

  useEffect(() => {
    if (UserType == 'Therapist') {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequest?requestString=${selectedValue}&Therpistid=${Userid}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
          setData(response)

        })
        .catch(error => { })

    } else {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequest?requestString=${selectedValue}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response)
          setData(response)

        })
        .catch(error => { })

    }
  }, [refresh, selectedValue, UserType])

  const openLeaveRequestRegistration = (event, id = 0) => {
    navigate('/LeaveRegistration', { state: { LeaveRequestid: id } })
  }
  const handleDownload = async () => {
    const doc = new jsPDF();
    doc.text('Leave Request', 14, 20);
    const columns = [

      { header: 'ID', dataKey: 'id' },
      { header: 'leave Purpose', dataKey: 'leavePurpose' },
      { header: 'Additional Information', dataKey: 'additionalInfo' },
      { header: 'Start Date', dataKey: 'leaveStartDate' },
      { header: 'End Date', dataKey: 'leaveEndDate' },
      { header: 'Approved By', dataKey: 'createdBy' },
    ];

    const rows = Data.map(item => ({
      id: item.id,
      leavePurpose: item.leavePurpose,
      additionalInfo: item.additionalInfo,
      leaveStartDate: item.leaveStartDate,
      leaveEndDate: item.leaveEndDate,
      createdBy: item.createdBy,
    }));



    doc.autoTable({
      head: [columns.map(col => col.header)],
      body: rows.map(item => Object.values(item)),
      startY: 30,
    });

    const fileName = `leave_Request${moment(new Date()).format('DD_MMM_YY')}.pdf`;

    doc.save(fileName);
  };
  const [TherapistList, setTherapistList] = useState([]);
  const { control, setValue, getValues } = useForm();
  const [seletedTherpist, setseletedTherpist] = useState(null);

  const onTherapistSearch = (therapistId) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequest?Therpistid=${therapistId}`, {
        headers
      })
      .then(response => {
        setLoading(false);
        setRows(response); // Assuming response.data contains the data
      })
      .catch(error => {
        setLoading(false);
        console.error("Error fetching user notes:", error);
      });
  };
  const userType = localStorage.getItem('User_Type');

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.userName,
            value: field.id
          });
        });
        setTherapistList(prac);



      })
      .catch(error => { });

  }, []);
  return (
    <div className="page-content">
      <Container >
        <Grid container spacing={1}>
          <Grid item xs={0.9} sm={0.9} xl={0.9} >
            <Typography style={{ color: 'black', fontSize: '18px', marginTop: '6px' }}>
              Type:
            </Typography>
          </Grid>
          <Grid item xs={5.5} sm={5.5} xl={5.5}>
            <NativeSelect
              onChange={handleChange}

              value={selectedValue}


              style={{
                marginLeft: '7px',
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '22px',
                letterSpacing: '0px',
                textAlign: 'left',
                borderBottom: 'none',
                textDecoration: 'none', width: '300px'
              }}
            >

              {/* <option value='All'>All</option> */}
              <option value='all'>All</option>
              <option value='pending'>Pending</option>
              <option value='approved'>Approved</option>

            </NativeSelect>
          </Grid>

          <Grid item xs={1.8} sm={1.8} xl={1.8}>
            <Button className="Button" onClick={onFilterSearch} variant='contained' style={{
              fontSize: '14px', fontWeight: 'bold',
              float: 'right'
            }}>
              <CachedOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px',

                }}
              ></CachedOutlinedIcon>
              Refresh
            </Button>
          </Grid>
          <Grid item xs={1.7} sm={1.7} xl={1.7}>
            <Button className="Button" onClick={openLeaveRequestRegistration} variant='contained' style={{
              fontSize: '14px', fontWeight: 'bold',
            }}>
              <AddOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px',

                }}
              ></AddOutlinedIcon>
              Add New
            </Button>
          </Grid>

          <Grid item xs={2} sm={2} xl={2}>
            <Button
              className="Button"
              onClick={handleDownload}
              fullWidth
              disabled={!Data || Data.length === 0}
              variant='contained' style={{
                fontSize: '14px', fontWeight: 'bold',
                float: 'right'
              }}
              type="submit"
            >
              Download
              <DownloadForOfflineIcon />
            </Button>
          </Grid>

          {userType === 'Supervisor' || userType ==='Admin'  ? (
            <Paper elevation={4} style={{
              padding: '16px', backgroundColor: '#f5f5f5', borderRadius: '8px', marginBottom: '16px', width: '500px', margin: '10px', border: '2px solid #326C1D',

            }}>
              <Typography variant="h6" style={{ marginBottom: '12px', fontWeight: 'bold', color: '#333' }}>
                Filter by Therapist
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography style={{ color: '#333', fontSize: '16px', marginRight: '8px', minWidth: '100px' }}>
                  Therapist:
                </Typography>
                <ReactSelect
                  options={TherapistList}
                  value={TherapistList.find(option => option.value === getValues("therapistsId"))}
                  onChange={selectedOption => {
                    setValue("therapistsId", selectedOption.value);
                    setseletedTherpist(selectedOption.value);
                    onTherapistSearch(selectedOption.value); // Call the filter function when an option is selected
                  }}
                  styles={{
                    container: (base) => ({
                      ...base,
                      width: '100%',
                      maxWidth: '300px',
                    }),

                  }}
                  placeholder="Select Therapist"
                />
              </Box>
            </Paper>
          ) :''}
          <Grid item xs={12} sm={12} xl={12}>
            <Box mb={0} style={{ marginTop: 0 }}>
              {isLoading ? (
                <div style={{ margin: '5rem', textAlign: 'center' }}>
                  <CircularProgress />
                </div>
              ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rows === null || rows.length === 0 ? ("No Record Found") : (
                    <DataGrid
                      rows={rows}
                      classes={gridClasses}
                      rowHeight={20}
                      columns={columns}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>


  )
}

export default LeaveRequest
