import { useState, useEffect, useRef } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import InputMask from 'react-input-mask';
import RadioGroup from '@mui/material/RadioGroup'
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from '@mui/material/Paper'

import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileUploader } from "react-drag-drop-files";



const SitePageRegistration = props => {
  const fields = [
    "id",
    "userId",
    "practiceId",
    "managerPhone",
    "address",
    "phone",
    "website",
    "startDate",
    "endDate",
    "locationName",
    "locationType",
    "otherDetails",
    "inActive",
    "otherDetails",
    "otherDetailsCounseling",
    "hospital",
    "school",
    "government",
    "privatePractice",
    "agency",
    "nonProfit",
    "otherCheck",
    "general",
    "groups",
    "marriageFamily",
    "drugAlcohol",
    "carrierVocational",
    "rehabilitation",
    "academic",
    "childAdolescent",
    "artTherapy",
    "otherCounselingCheck",
    "userName"
  ];
  const defValues = {
    id: 0,
    userId: 0,
    practiceId: 0,
    managerPhone: "",
    address: "",
    phone: "",
    website: "",
    startDate: "",
    endDate: "",
    locationName: "",
    locationType: "",
    otherDetails: "",
    inActive: true,
    otherDetails: "",
    otherDetailsCounseling: "",
    hospital: "",
    school: "",
    government: "",
    privatePractice: "",
    agency: "",
    nonProfit: "",
    otherCheck: "",
    general: "",
    groups: "",
    marriageFamily: "",
    drugAlcohol: "",
    carrierVocational: "",
    rehabilitation: "",
    academic: "",
    childAdolescent: "",
    artTherapy: "",
    otherCounselingCheck: "",
    userName: ""

  };
  const modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #EA7772',
    boxShadow: 24,
    overflow: 'hidden',
    p: 4,
    borderRadius: '8px',
    overflow: 'auto',
  };
  const [Emergency, setEmergency] = React.useState(false);
  const handleOpenEmergency = () => setEmergency(true);
  const handleCloseEmergency = () => setEmergency(false);
  const [isLoading, setisLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [userName, setuserName] = useState("");
  console.log('userName', userName)
  const [userType, setuserType] = useState("");
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [errorReceived, seterrorReceived] = useState("");
  const [zipDownloaded, setzipDownloaded] = useState(false);
  const [fetchedData, setfetchedData] = useState(false);
  const [userPractice, setUserPractice] = useState(0);
  const [userFacility, setUserFacility] = useState(0);
  const [rows, setRows] = useState(null);
  const [rows1, setRows1] = useState(null);
  const [Dob, setDob] = React.useState(Moment().format('YYYY-MM-DD'))
  const [licenseApprovalDate, setlicenseApprovalDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [licenseExpirationDate, setlicenseExpirationDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [dateidissued, setDateidissued] = React.useState(Moment().format('YYYY-MM-DD'))
  const [dateIDExpiration, setDateIDExpiration] = React.useState(Moment().format('YYYY-MM-DD'))
  const [insurancePolicyDate, setInsurancePolicyDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [insuranceEndDate, setInsuranceEndDate] = React.useState(Moment().format('YYYY-MM-DD'))






  const [providerType, setProviderType] = React.useState("");
  const [userChildList, setUsersChildList] = React.useState([]);
  const [usersList, setUsersList] = React.useState([]);
  const [selectedUserIds, setselectedUserIds] = React.useState([]);
  const [selectUserError, setselectUserError] = useState(false);
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [cellPhone, setcellPhone] = React.useState()

  const location = useLocation();
  const _userId = props.uid;


  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  // const Emailaddress = localStorage.getItem("Emailaddress")
  const [Emailaddress, setEmailaddress] = useState("");
  const [practicesList, setpracticesList] = useState("");
  console.log("practicesList", practicesList);
  const [practiceslistid, setpracticeslistid] = useState([]);
  //   const practiceslistid = [];
  console.log("practiceslistid", practiceslistid);
  const facilitiesList = [];
  const [practiceIdError, setPracticeIDError] = useState(false);
  const [providerIdError, setProviderIdError] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);
  const [practiceID, setPracticeID] = useState("");
  console.log("aaaa", practiceID);
  const [userProvider, setUserProvider] = useState(0);
  const [providers, setProviders] = useState([]);
  const [defaultPracticeError, setDefaultPracticeError] = useState(false);
  const [defaultPracticeErrorId, setDefaultPracticeErrorId] = useState("");
  const validationSchema = Yup.object({
    // fullName: Yup.string().required("Full Name is required"),
    locationName: Yup.string().required("Site  Name is required"),
    userId: Yup.number().nullable().required("Please select user"),
    // defaultPracticeId: Yup.array().required("Practice Name is required")
    startDate: Yup.date()
      .nullable()
      .required("Please select a date")
      .typeError("Please select a date"),
    endDate: Yup.date()
      .nullable()
      .required("Please select a date")
      .typeError("Please select a date"),
      managerPhone: Yup.string()
      .required("Please Enter Manager Phone"),
      phone: Yup.string()
      .required("Please Enter Phone"),
      address: Yup.string()
      .required("Please Enter address")
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const locationType = watch('locationType', '');
  const carrierVocational = watch('otherCounselingCheck', false);

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }

  const [Emergencycontantid, setEmergencycontantid] = useState("");

  const [Emergencycontant, setEmergencycontant] = useState([]);
  console.log("testconat", Emergencycontant)
  const [Namecontant, setNamecontant] = useState("");
  const [Relationshipcontant, setRelationshipcontant] = useState("");
  const [managerPhone, setmanagerPhone] = useState("");
  const [Phone, setPhone] = useState("");
  const [endDate, setendDate] = React.useState('')
  const [startDate, setstartDate] = React.useState('')


  const [Emailcontant, setEmailcontant] = useState("");
  const [Addresscontant, setAddresscontant] = useState("");


  // const onchangeEmergency = () => {
  //   setEmergencycontant(prevEmergencycontant => [...prevEmergencycontant, { Namecontant }]);
  // }
  const [userIdError, setUserIdError] = useState('');

  const onSubmit = data => {

    let postData = data;

    if (!data.userId) {
      setUserIdError('Please select a user.');
      return;
    }

    setSubmitting(true);
    seterrorReceived("");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Locations/AddLocations`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);
          toast.success('Record saved successfully', {
            position: "bottom-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",

            onClose: () => {
              props.handleClose()

            }

          });
          // alert.success("Record saved successfully.", {
          //   type: "success",
          //   onClose: () => {
          //     navigate("/SitePage");
          //   }
          // });
        } else if (response.length > 0) {
          seterrorReceived(response.data);
          setSubmitting(false);
          return;
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
  const [userEmail, setUserEmail] = useState("");



  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Locations/getLocationById?id=${_userId}`, {
        headers
      })
      .then(response => {
        fields.forEach(field => {
          setValue(field, response[field]);
          console.log("response.data: ", response);
          setuserType(response["userType"]);
          setmanagerPhone(response.managerPhone)
          setPhone(response.phone)
          var dateYMD = Moment(response.endDate).format('YYYY-MM-DD')
          setendDate(dateYMD)
          var dateYMD = Moment(response.startDate).format('YYYY-MM-DD')
          setstartDate(dateYMD)


        });



      })
      .catch(error => { });
  }, []);


  const [images, setImages] = React.useState(null);

  const Input = styled("input")({
    display: "none"
  });

  const [open, setOpen] = React.useState(false);

  function handleCancel() {
    navigate("/SitePage");

  }
  const [tabValue, settabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    settabValue(newValue);
    setValue("defaultPracticeId", "");
    setValue("provider_id", "");
    setPracticeID("");
    setProviderIdError(false);
    setPracticeIDError(false);
    setDefaultPracticeError(false);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 350,
    bgcolor: 'white',
    border: '2px solid #377562',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 2,
    borderRadius: '8px',
    overflow: 'auto',

  }
  const [docError, setdocError] = useState(false)
  const [filename, setfilename] = useState([])
  const [base64Data, setBase64Data] = useState([])
  const [totalProgress, setTotalProgress] = React.useState(0)
  const [savedFiles, setSavedFiles] = React.useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const inputRef = useRef(null)
  // document Uplaod
  const fileToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  ///////////////////////////////////
  //Single and multiple files
  const handleChangeforfile = async files => {
    const newFilenames = []
    const newBase64Data = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const base64 = await fileToBase64(file)
      newFilenames.push(file.name)
      newBase64Data.push(base64)
    }

    setfilename(newFilenames)
    setBase64Data(newBase64Data)
    setdocError(false)
  }
  const [SuperviseID, setSuperviseID] = useState("");
  const [SuperviseList, setSuperviseList] = useState([]);
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Supervisor'}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.userName,
            value: field.id
          });
        });
        setSuperviseList(prac);

      })
      .catch(error => { });

  }, []);

  return (
    <React.Fragment>
      <Grid container>

        {/* <Box sx={style}> */}

        <Grid container spacing={1}>


          <Grid item xs={0.2} sm={0.2} xl={0.2}> </Grid>

          <Grid item xs={5.8} sm={5.8} xl={5.8}>
            <Typography style={{ fontSize: '14px' }}>
              Site Name
            </Typography>
          </Grid>
          <Grid item xs={5.5} sm={5.5} xl={5.5}>
            <Typography style={{ fontSize: '14px' }}>
              Site Supervisor
            </Typography>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

          <Grid item xs={0.2} sm={0.2} xl={0.2}> </Grid>

          <Grid item xs={5.8} sm={5.8} xl={5.8}>
            <Controller
              name="locationName"
              {...register("locationName")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="Please enter site name"
                  {...field}
                  fullWidth
                  error={errors.locationName}
                />}
            />
            <p style={{ color: 'red', fontSize: '14px' }}>{errors.locationName?.message}</p>
          </Grid>
          <Grid item xs={5.5} sm={5.5} xl={5.5}>
            {/* <Controller
                        name="userName"
                        {...register("userName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter Supervisor"
                            {...field}
                            fullWidth
                          
                          />}
                      /> */}
            <Controller
              name="userId"
              {...register("userId")}
              control={control}
              render={({ field }) =>
                <ReactSelect
                  {...field}
                  fullWidth
                  value={SuperviseList.filter(function (option) {
                    return (
                      option.value === getValues("userId")
                    );
                  })}

                  onChange={e => {
                    setValue("userId", e.value);
                    setSuperviseID(e.value);
                    setUserIdError('');
                  }}
                  error={errors.userId}

                  options={SuperviseList}
                />

              }
            />
            {userIdError && <span style={{ color: 'red' }}>{userIdError}</span>}

          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

          <Grid item xs={0.2} sm={0.2} xl={0.2}> </Grid>

          <Grid item xs={5.8} sm={5.8} xl={5.8}>
            <Typography style={{ fontSize: '14px' }}>
              Site Manager Phone Number
            </Typography>
          </Grid>
          <Grid item xs={5.5} sm={5.5} xl={5.5}>
            <Typography style={{ fontSize: '14px' }}>
              Address
            </Typography>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
          <Grid item xs={0.2} sm={0.2} xl={0.2}> </Grid>


          <Grid item xs={5.8} sm={5.8} xl={5.8}>
            <InputMask
              mask='+1 999-999-9999'
              disabled={false}
              value={managerPhone}
              style={{ height: '30px' }}
              onChange={ev => {
                console.log('ee:', ev.target.value)
                setValue('managerPhone', ev.target.value)
                setmanagerPhone(ev.target.value)

              }
              }
            >
              {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='Enter cell phone' 
               error={!!errors.managerPhone}
               helperText={errors.managerPhone ? errors.managerPhone.message : ''}
              />}
            </InputMask>
          </Grid>
          <Grid item xs={5.5} sm={5.5} xl={5.5}>
            <Controller
              name="address"
              {...register("address")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="Please enter address"
                  {...field}
                  fullWidth
                  error={!!errors.address}
                  helperText={errors.address ? errors.address.message : ''}
  
                />}
            />

          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

          <Grid item xs={0.2} sm={0.2} xl={0.2}> </Grid>

          <Grid item xs={5.8} sm={5.8} xl={5.8}>
            <Typography style={{ fontSize: '14px' }}>
              Phone
            </Typography>
          </Grid>
          <Grid item xs={5.5} sm={5.5} xl={5.5}>
            <Typography style={{ fontSize: '14px' }}>
              Website
            </Typography>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

          <Grid item xs={0.2} sm={0.2} xl={0.2}> </Grid>

          <Grid item xs={5.8} sm={5.8} xl={5.8}>
            <InputMask
              mask='+1 999-999-9999'
              disabled={false}
              value={Phone}
              style={{ height: '30px' }}
              onChange={ev => {
                console.log('ee:', ev.target.value)
                setValue('phone', ev.target.value)
                setPhone(ev.target.value)

              }
              }
            >
              {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='Enter cell phone'
                       error={!!errors.address}
                       helperText={errors.phone ? errors.phone.message : ''}
              />}
            </InputMask>

          </Grid>
          <Grid item xs={5.5} sm={5.5} xl={5.5}>
            <Controller
              name="website"
              {...register("website")}
              control={control}
              render={({ field }) =>
                <TextField
                  size="small"
                  placeholder="Please enter website"
                  {...field}
                  fullWidth

                />}
            />

          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

          <Grid item xs={0.2} sm={0.2} xl={0.2}> </Grid>

          <Grid item xs={5.8} sm={5.8} xl={5.8}>
            <Typography style={{ fontSize: '14px' }}>
              Site Start Date
            </Typography>
          </Grid>
          <Grid item xs={5.5} sm={5.5} xl={5.5}>
            <Typography style={{ fontSize: '14px' }}>
              Site End Date
            </Typography>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

          <Grid item xs={0.2} sm={0.2} xl={0.2}> </Grid>

          <Grid item xs={5.8} sm={5.8} xl={5.8}>
            <Controller
              name="startDate"
              // {...register("dob")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='date'
                  size='small'
                  fullWidth
                  {...field}
                  value={startDate ? startDate : ''}
                  onChange={e => {
                    setValue('startDate', e.target.value)
                    setstartDate(e.target.value)
                  }}
                  error={errors.startDate}

                />

              }
            />
            <p style={{ color: 'red', fontSize: '14px' }}>{errors.startDate?.message}</p>

          </Grid>
          <Grid item xs={5.5} sm={5.5} xl={5.5}>
            <Controller
              name="endDate"
              // {...register("dob")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='date'
                  size='small'
                  fullWidth
                  {...field}
                  value={endDate ? endDate : ''}
                  onChange={e => {
                    setValue('endDate', e.target.value)
                    setendDate(e.target.value)
                  }}
                  error={errors.endDate}


                />
              }
            />
            <p style={{ color: 'red', fontSize: '14px' }}>{errors.endDate?.message}</p>

          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

          <Grid container >
            <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
            <Grid item xs={11} sm={11} xl={11}>
              <Grid container spacing={1} style={{ border: '2px solid #96ACEF', borderRadius: '5px', marginTop: "10px", }}>
                <Grid item xs={12} sm={12} xl={12}>
                  <Typography style={{ fontSize: '14px', color: 'black', fontWeight: 'bold' }}>
                    Site Type: (Select one below):

                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <div>
                      <input

                        type="radio"
                        value="Hospital"
                        name="locationType"
                        {...register("locationType")}
                        style={{
                          // marginLeft: "30px",
                          transform: "scale(1.0)",
                          marginRight: "5px",
                        }}
                      />
                      {""}
                      <label style={{ fontSize: "14px", fontWeight: "normal" }}>
                        Hospital
                      </label>
                      <input
                        type="radio"
                        value="School"
                        name="locationType"
                        {...register("locationType")}
                        style={{
                          marginLeft: "10px",
                          transform: "scale(1.0)",
                          marginRight: "5px",
                        }}
                      />
                      {""}
                      <label style={{ fontSize: "14px", fontWeight: "normal" }}>
                        School
                      </label>
                      <input

                        type="radio"
                        value="Government"
                        name="locationType"
                        {...register("locationType")}
                        style={{
                          marginLeft: "10px",
                          transform: "scale(1.0)",
                          marginRight: "5px",
                        }}
                      />
                      {""}
                      <label style={{ fontSize: "14px", fontWeight: "normal" }}>
                        Government
                      </label>
                      <input

                        type="radio"
                        value="PrivatePractice"
                        name="locationType"
                        {...register("locationType")}
                        style={{
                          marginLeft: "10px",
                          transform: "scale(1.0)",
                          marginRight: "5px",
                        }}
                      />
                      {""}
                      <label style={{ fontSize: "14px", fontWeight: "normal" }}>
                        Private Practice
                      </label>
                      <input

                        type="radio"
                        value="Agency"
                        name="locationType"
                        {...register("locationType")}
                        style={{
                          marginLeft: "10px",
                          transform: "scale(1.0)",
                          marginRight: "5px",
                        }}
                      />
                      {""}
                      <label style={{ fontSize: "14px", fontWeight: "normal" }}>
                        Agency
                      </label>
                      <input

                        type="radio"
                        value="NonProfit"
                        name="locationType"
                        {...register("locationType")}
                        style={{
                          marginLeft: "10px",
                          transform: "scale(1.0)",
                          marginRight: "5px",
                        }}
                      />
                      {""}
                      <label style={{ fontSize: "14px", fontWeight: "normal" }}>
                        Non-Profit
                      </label>
                      <input

                        type="radio"
                        value="Other"
                        name="locationType"
                        {...register("locationType")}
                        style={{
                          marginLeft: "17px",
                          transform: "scale(1.0)",
                          marginRight: "5px",
                        }}
                      />
                      {""}
                      <label style={{ fontSize: "14px", fontWeight: "normal" }}>
                        Other
                      </label>
                    </div>
                  </RadioGroup>
                </Grid>
                {locationType === 'Other' && (
                  <>
                    <Grid item xs={6} sm={6} xl={6}>
                      <Typography style={{ fontSize: '14px', marginTop: '10px', marginBottom: '10px' }}>
                        Type Other Details if selected other above:

                      </Typography>

                    </Grid>
                    <Grid item xs={5.5} sm={5.5} xl={5.5} style={{ marginBottom: '10px' }}>
                      <Controller
                        name="otherDetails"
                        {...register("otherDetails")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter other details"
                            {...field}
                            fullWidth

                          />}
                      />

                    </Grid>
                  </>
                )}
                <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
              </Grid>
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>





          </Grid>
          <Grid container >
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={11} sm={11} xl={11}>
              <Grid container spacing={1} style={{ border: '2px solid #96ACEF', borderRadius: '5px', marginTop: "10px", }}>

                <Grid item xs={12} sm={12} xl={12}>
                  <Typography style={{ fontSize: '14px', color: 'black', fontWeight: 'bold' }}>
                    Type of Counseling Services: (select all that Applies)

                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                  <Typography style={{ fontSize: '14px', }}>
                    General
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                  <Typography style={{ fontSize: '14px', }}>
                    Group
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                  <Typography style={{ fontSize: '14px', }}>
                    Marriage & Family
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                  <Typography style={{ fontSize: '14px', }}>
                    Drug & Alcohol
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                  <Typography style={{ fontSize: '14px', }}>
                    Career & Vocational
                  </Typography>
                </Grid>

                <Grid item xs={2} sm={2} xl={2}>
                  <input
                    name='general'
                    size='small'
                    type='checkbox'
                    {...register('general')}
                    id='general'
                  />
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                  <input
                    name='groups'
                    size='small'
                    type='checkbox'
                    {...register('groups')}
                    id='groups'
                  />
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                  <input
                    name='marriageFamily'
                    size='small'
                    type='checkbox'
                    {...register('marriageFamily')}
                    id='marriageFamily'
                  />
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                  <input
                    name='drugAlcohol'
                    size='small'
                    type='checkbox'
                    {...register('drugAlcohol')}
                    id='drugAlcohol'
                  />
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                  <input
                    name='carrierVocational'
                    size='small'
                    type='checkbox'
                    {...register('carrierVocational')}
                    id='carrierVocational'
                  />
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                  <Typography style={{ fontSize: '14px', }}>
                    Rehabilitation
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                  <Typography style={{ fontSize: '14px', }}>
                    Academic
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                  <Typography style={{ fontSize: '14px', }}>
                    Child & Adolescent
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                  <Typography style={{ fontSize: '14px', }}>
                    Art Therapy


                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                  <Typography style={{ fontSize: '14px', }}>
                    Other
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                  <input
                    name='rehabilitation'
                    size='small'
                    type='checkbox'
                    {...register('rehabilitation')}
                    id='rehabilitation'
                  />
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                  <input
                    name='academic'
                    size='small'
                    type='checkbox'
                    {...register('academic')}
                    id='academic'
                  />
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                  <input
                    name='childAdolescent'
                    size='small'
                    type='checkbox'
                    {...register('childAdolescent')}
                    id='childAdolescent'
                  />
                </Grid>
                <Grid item xs={2} sm={2} xl={2}>
                  <input
                    name='artTherapy'
                    size='small'
                    type='checkbox'
                    {...register('artTherapy')}
                    id='artTherapy'
                  />
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                  <input
                    name='otherCounselingCheck'
                    size='small'
                    type='checkbox'
                    {...register('otherCounselingCheck')}
                    id='otherCounselingCheck'
                  />
                </Grid>
                {carrierVocational && (
                  <>
                    <Grid item xs={6} sm={6} xl={6}>
                      <Typography style={{ fontSize: '14px', marginTop: '10px' }}>
                        Type Other Details if selected other above:

                      </Typography>

                    </Grid>
                    <Grid item xs={5.5} sm={5.5} xl={5.5} style={{ marginBottom: '10px' }}>
                      <Controller
                        name="otherDetailsCounseling"
                        {...register("otherDetailsCounseling")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please enter other details"
                            {...field}
                            fullWidth

                          />}
                      />

                    </Grid>
                  </>
                )}
                <Grid item xs={0.5} sm={0.5} xl={0.5}>

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
          </Grid>

          <Grid item xs={0.2} sm={0.2} xl={0.2}> </Grid>

          <Grid item xs={5.8} sm={5.8} xl={5.8} style={{ marginTop: '10px' }}>
            <Button
              className="Button"
              onClick={handleSubmit(onSubmit)}
              disabled={submitting}
              fullWidth
              style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
              type="submit"
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={5.5} sm={5.5} xl={5.5} style={{ marginTop: '10px' }}>
            <Button
              className="Button"
              onClick={props.handleClose}
              disabled={submitting}
              fullWidth
              style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}

            >

              Cancel
            </Button>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>


        </Grid>




        {/* </Box> */}
      </Grid>
    </React.Fragment>
  );
};
export default SitePageRegistration;
