// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography, NativeSelect, Modal, Paper } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import { ToastContainer, toast } from 'react-toastify';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import IncidentRegistration from '../IncidentRegistration'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import moment from 'moment'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import CheckIcon from '@mui/icons-material/Check';
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import CloseIcon from '@mui/icons-material/Close';
const IncidentReporting = () => {
  document.title = "IncidentReporting | Maevisory";

  const [isLoading, setLoading] = useState(false)
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }

  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [refresh, setRefresh] = useState(false)
  function applyRefresh() {
    setRefresh(!refresh)
  }
  const navigate = useNavigate();
  const [IncidentReportingid, setIncidentReportingid] = useState('')
  const [IncidentReporting, setIncidentReporting] = React.useState(false);
  const handleIncidentReporting = () => {
    setIncidentReporting(true)
  };
  const handlecloseIncidentReporting = () => {
    setIncidentReportingid('')
    // setLomnRequestid('')
    //  setPatientNamee('')
    setIncidentReporting(false)
  };
  const columns = [
    {
      field: 'incidentType',
      headerClassName: 'super-app-theme--header',
      headerName: 'Incident Type ',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: cellValues => (
        <Button
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            // openIncidentRegistration(event,cellValues.row.id )
            setIncidentReportingid(cellValues.row.id)
          }}
        >{`${cellValues.row.incidentType}`}</Button>
      )
    },

    // {
    //   field: 'supervisorFirstName',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'First Name',
    //   flex: 0.3
    // },
    {
      field: 'therapistsName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Therapist',
      flex: 0.3
    },
    // {
    //   field: 'incidentType',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'Incident Type',
    //   flex: 0.3

    // },

    {
      field: 'dateTime',
      headerClassName: 'super-app-theme--header',
      headerName: 'Date',
      flex: 0.3
    },
    {
      field: 'createdDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'Activity time stamp ',
      flex: 0.35,
      renderCell: (params) => {
        return params.value ? moment(params.value).format('MM/DD/YYYY HH:mm') : '';
      },
    },
    {
      field: 'approveDateTime',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approved Date',
      flex: 0.3,
      renderCell: (params) => {
        return params.value ? moment(params.value).format('MM/DD/YYYY HH:mm') : '';
      },
    },
    {
      field: 'approvedBy',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approved By',
      flex: 0.3
    },
    // {
    //   field: 'starTtime',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'Time',
    //   flex: 0.3,
    //   renderCell: cellValues => {
    //     const time = cellValues.row.starTtime;

    //     console.log('Raw time value:', time);

    //     let formattedTime = '';
    //     if (time) {
    //       // Try parsing with different formats
    //       const formats = ['HH:mm', 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss', 'HH:mm:ss'];
    //       for (const format of formats) {
    //         const momentTime = moment(time, format, true);
    //         if (momentTime.isValid()) {
    //           formattedTime = momentTime.format('HH:mm');
    //           break;
    //         }
    //       }
    //     }

    //     return <span>{formattedTime}</span>;
    //   }
    // },
    {
      field: 'approve',
      headerClassName: 'super-app-theme--header',
      headerName: 'Approve',
      flex: 0.2,
      renderCell: cellValues => {
        return cellValues.row.approve ? (
          <CheckIcon style={{ color: 'green' }} />
        ) : (
          <CloseIcon style={{ color: 'red' }} />
        )
      }
    },
    {
      field: 'isActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {

                      inActive: true,
                      id: cell.row.id
                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/IncidentReport/AddIncidentReport`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }

  ]
  useEffect(() => {
    if (IncidentReportingid > 0) {
      handleIncidentReporting()
    }
  }, [IncidentReportingid]);
  const [selectedValue, setSelectedValue] = useState('')
  const handleChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    applyRefresh(newValue)
    console.log("Selected value my kya ha : ", newValue)
  }
  const [rows, setRows] = useState(null)
  // useEffect(() => {
  //   setLoading(true)

  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/IncidentReport/getIncidentReports`, { headers })
  //     .then(response => {
  //       setLoading(false)
  //       setRows(response)
  //     })
  //     .catch(error => {})

  // }, [refresh])

  const onFilterSearch = event => {
    if (userType == 'Therapist') {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/IncidentReport/getIncidentReports?requestString=${selectedValue}&Therpistid=${Userid}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response);
          setData(response)
        })
        .catch(error => { })
    } else {

      axios
        .get(`${process.env.REACT_APP_API_URL}/IncidentReport/getIncidentReports?requestString=${selectedValue}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response);
          setData(response)
        })
        .catch(error => { })
    }

  }
  const [Data, setData] = useState([])

  const userType = localStorage.getItem('User_Type');
  const userName = localStorage.getItem('UserName');
  const Userid = localStorage.getItem('userid')

  useEffect(() => {
    if (userType == 'Therapist') {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/IncidentReport/getIncidentReports?requestString=${selectedValue}&Therpistid=${Userid}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response);
          setData(response)
        })
        .catch(error => { })
    } else {

      axios
        .get(`${process.env.REACT_APP_API_URL}/IncidentReport/getIncidentReports?requestString=${selectedValue}`, { headers })
        .then(response => {
          setLoading(false)
          setRows(response);
          setData(response)
        })
        .catch(error => { })
    }

  }, [refresh, selectedValue])

  const openIncidentRegistration = (event, id = 0) => {
    navigate('/IncidentRegistration', { state: { uid: id } })
  }
  const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #EA7772',
    boxShadow: 24,
    // overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',


  }

  const handleDownload = async () => {
    const doc = new jsPDF();
    doc.text('Incident Report ', 14, 20);
    // Define the columns
    const columns = [

      { header: 'ID', dataKey: 'id' },
      { header: 'Incident Type', dataKey: 'incidentType' },
      { header: 'Date', dataKey: 'dateTime' },
      { header: 'Time', dataKey: 'starTtime' },
      { header: 'Therapists Name', dataKey: 'therapistsName' },
    ];

    // Map data to the rows
    const rows = Data.map(item => ({
      id: item.id,
      incidentType: item.incidentType,
      dateTime: item.dateTime,
      starTtime: item.starTtime,
      therapistsName: item.therapistsName,
    }));


    // Add the data to the PDF using autoTable
    doc.autoTable({
      head: [columns.map(col => col.header)],
      body: rows.map(item => Object.values(item)),
      startY: 30, // Adjust starting point
    });

    // Generate the file name with the current date
    const fileName = `Incident_Report${moment(new Date()).format('DD_MMM_YY')}.pdf`;

    // Save the PDF
    doc.save(fileName);
  };
  const [TherapistList, setTherapistList] = useState([]);
  const { control, setValue, getValues } = useForm();
  const [seletedTherpist, setseletedTherpist] = useState(null);

  const onTherapistSearch = (therapistId) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/IncidentReport/getIncidentReports?Therpistid=${therapistId}`, {
        headers
      })
      .then(response => {
        setLoading(false);
        setRows(response);
      })
      .catch(error => {
        setLoading(false);
        console.error("Error fetching user notes:", error);
      });
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.userName,
            value: field.id
          });
        });
        setTherapistList(prac);



      })
      .catch(error => { });

  }, []);
  return (
    <>
      <Modal
        open={IncidentReporting}
        onClose={handlecloseIncidentReporting}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        visible={true} title={'Title'}
      >
        <Box sx={style}>
          <Headroom >
            <Grid
              container
              style={{ backgroundColor: '#356F60', position: 'sticky' }}
            >
              <Grid item xs={12} sm={12} xl={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'space-between',
                    height: '2rem'
                  }}
                >
                  <DialogTitle
                    style={{
                      flex: 1,
                      // textAlign: 'left',
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: 'bold',
                        // textAlign: 'left',
                        marginTop: '-10px',
                        color: '#fff',
                        textDecoration: 'underline',
                        fontSize: '1rem'

                      }}
                    >
                      Incident Registration

                    </h1>
                  </DialogTitle>
                  <CancelOutlinedIcon
                    onClick={handlecloseIncidentReporting}
                    fontSize="large"
                    color="red"
                    style={{
                      color: 'red',
                      paddingRight: '5px',
                      cursor: 'pointer',
                      float: 'right'
                      // marginLeft: 'auto',
                      // marginRight: '1.5rem'
                    }}
                  ></CancelOutlinedIcon>
                </div>
              </Grid>
            </Grid>
          </Headroom>




          <Grid item xs={12} sm={12} xl={12} >
            <Card bordered={false} style={{
              overflow: 'auto',
              /* Other styles */
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: '#356F60 transparent', // thumb color and track color
              '&::-webkit-scrollbar': {
                width: 8, /* Width of vertical scrollbar */
                height: 8, /* Height of horizontal scrollbar */
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#356F60', /* Color of the thumb */
                borderRadius: 10,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent', /* Color of the track */
              },
              /* Adjust the width for the horizontal scrollbar */
              '&::-webkit-scrollbar-thumb:horizontal': {
                width: 4, /* Width of horizontal scrollbar */
              },
              // zIndex: "10",


              height: '80vh'
            }} >
              <IncidentRegistration uid={IncidentReportingid} handleClose={handlecloseIncidentReporting} selectedValue={selectedValue} ></IncidentRegistration>


            </Card>
            <ToastContainer
              position="bottom-center"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"

            />
          </Grid>


        </Box>
      </Modal>
      <div className="page-content">
        <Container >
          <Grid container spacing={1}>
            <Grid item xs={0.9} sm={0.9} xl={0.9} >
              <Typography style={{ color: 'black', fontSize: '18px', marginTop: '6px' }}>
                Type:
              </Typography>
            </Grid>
            <Grid item xs={5.5} sm={5.5} xl={5.5}>
              <NativeSelect
                onChange={handleChange}

                value={selectedValue}


                style={{
                  marginLeft: '7px',
                  fontFamily: 'Inter',
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '22px',
                  letterSpacing: '0px',
                  textAlign: 'left',
                  borderBottom: 'none',
                  textDecoration: 'none', width: '300px'
                }}
              >

                {/* <option value='All'>All</option> */}
                <option value='all'>All</option>
                <option value='pending'>Pending</option>
                <option value='approved'>Approved</option>

              </NativeSelect>
            </Grid>

            <Grid item xs={1.8} sm={1.8} xl={1.8}>
              <Button className="Button" onClick={onFilterSearch} variant='contained' style={{
                fontSize: '14px', fontWeight: 'bold',
                float: 'right'
              }}>
                <CachedOutlinedIcon
                  fontSize='small'
                  style={{
                    color: 'white',
                    paddingRight: '4px',
                    paddingBottom: '2px',

                  }}
                ></CachedOutlinedIcon>
                Refresh
              </Button>
            </Grid>
            <Grid item xs={1.7} sm={1.7} xl={1.7} >
              <Button className="Button" onClick={handleIncidentReporting} variant='contained' style={{
                fontSize: '14px', fontWeight: 'bold',
                float: 'right'
              }} >
                <AddOutlinedIcon />

                Add New
              </Button>

            </Grid>

            <Grid item xs={2} sm={2} xl={2}>
              <Button
                className="Button"
                onClick={handleDownload}
                fullWidth
                disabled={!Data || Data.length === 0}
                variant='contained' style={{
                  fontSize: '14px', fontWeight: 'bold',
                  float: 'right'
                }}
                type="submit"
              >
                Download
                <DownloadForOfflineIcon />
              </Button>
            </Grid>

            {userType === 'Supervisor' || userType ==='Admin'  ? (
              <Paper elevation={4} style={{
                padding: '16px', backgroundColor: '#f5f5f5', borderRadius: '8px', marginBottom: '16px', width: '500px', margin: '10px', border: '2px solid #326C1D',

              }}>
                <Typography variant="h6" style={{ marginBottom: '12px', fontWeight: 'bold', color: '#333' }}>
                  Filter by Therapist
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography style={{ color: '#333', fontSize: '16px', marginRight: '8px', minWidth: '100px' }}>
                    Therapist:
                  </Typography>
                  <ReactSelect
                    options={TherapistList}
                    value={TherapistList.find(option => option.value === getValues("therapistsId"))}
                    onChange={selectedOption => {
                      setValue("therapistsId", selectedOption.value);
                      setseletedTherpist(selectedOption.value);
                      onTherapistSearch(selectedOption.value); // Call the filter function when an option is selected
                    }}
                    styles={{
                      container: (base) => ({
                        ...base,
                        width: '100%',
                        maxWidth: '300px',
                      }),

                    }}
                    placeholder="Select Therapist"
                  />
                </Box>
              </Paper>
            ):''}
            <Grid item xs={12} sm={12} xl={12}>
              <Box mb={0} style={{ marginTop: 0 }}>
                {isLoading ? (
                  <div style={{ margin: '5rem', textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div mb={0} style={{ height: 400, width: '100%' }}>
                    {rows === null || rows.length === 0 ? ("No Record Found") : (
                      <DataGrid
                        rows={rows}
                        classes={gridClasses}
                        rowHeight={20}
                        columns={columns}
                        getRowSpacing={getRowSpacing}
                        //checkboxSelection
                        fullWidth
                        sx={gridRowStyle}
                        pagination
                        autoPageSize
                      />
                    )}
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>

  )
}

export default IncidentReporting
