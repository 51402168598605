import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Moment from "moment";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Headroom from "react-headroom";
import DialogTitle from "@mui/material/DialogTitle";
import ReactSelect from "react-select";

import {
  InputLabel,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { InputAdornment } from '@mui/material';
import { Box, Button, Typography, Grid, TextField } from "@mui/material";
import { useAlert } from "react-alert";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
const TakePayment = (props) => {
  // API Authanticatiion
  const token = localStorage.getItem("Token");
  const auth = `Bearer ${token}`;
  const headers = {
    Authorization: auth,
  };

  
  const [TherapistID, setTherapistID] = useState("");
  const [disabledField, setDisabledField] = useState(false);
  const [requestStatus, setRequestStatus] = useState("");

  const fields = [
    "userId",
    "totalPayment",
    "paymentDate",
    "description",
    "paymentMode",
    "status"
  ];

  const defValues = {
    userId: 0,
    totalPayment: 0,
    paymentDate: null,
    description : '',
    paymentMode:'Cash',
    status:''
  };

  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const [Continue, setContinue] = useState(false);
  const [method , setmethod] = useState('Cash')
  const [Phone, setPhone] = useState("");
  const [num, setNum] = React.useState();
  const [userType, setUserType]= useState("");
  

  const [displayedDigits, setDisplayedDigits] = useState('');
  const navigate = useNavigate();
  
  const location = useLocation();
  const PaymentId = location.state.paymentId;

  const validationSchema = Yup.object({
    
  userId: Yup.number().min(1, "Please select a therapist").required("Please select a therapist"),
  totalPayment: Yup.number()
    .typeError("Only numbers are allowed")
    .required("Enter Payment Amount to proceed")
    .min(1, "Payment Amount must be greater than 0"),    
  // paymentDate: Yup.date().required("Seelct due date to proceed")   
  paymentDate: Yup.date()
    .nullable()
    .required("Please select a date"),
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });
  const [approve, setapprove] = useState(false);

  const onSubmit = data => {  

   
    let postData = data;

    data.id = PaymentId;

    console.log("SUBMITED DATA ==",data);
    
    setSubmitting(true);
    
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Payment/addPaymentRequest`,
        postData,
        { headers }
      )
      .then((response) => {
        if (response.id > 0) {
          setSubmitting(false);

          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
              navigate("/PaymentMethod");
            },
          });
        }
      })
      .catch((error) => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error",
        });
      });
  };
  const onSubmit1 = data => {  
   
    let postData = {
      ...data,
      id: PaymentId,
      compelet: true,
      status: "Completed",
    };
  
    console.log("SUBMITED DATA ==",data);
    
    setSubmitting(true);
    
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Payment/addPaymentRequest`,
        postData,
        { headers }
      )
      .then((response) => {
        if (response.id > 0) {
          setSubmitting(false);

          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
              navigate("/PaymentMethod");
            },
          });
        }
      })
      .catch((error) => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error",
        });
      });
  };


  function handleCancel() {
    navigate("/PaymentMethod");
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-40%, -50%)",
    width: 1000,
    maxHeight: '60vh',
    // height: 500,
    bgcolor: "white",
    border: "2px solid #377562",
    boxShadow: 24,
    // overflow: "hidden",
    // p: 4,
    borderRadius: "8px",
    overflow: "auto",
    /* WebKit (Chrome, Safari) */
    "&::-webkit-scrollbar": {
      width: 8 /* Width of vertical scrollbar */,
      height: 10,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#377562",
      borderRadius: 10,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    /* Adjust the width for the horizontal scrollbar */
    "&::-webkit-scrollbar-thumb:horizontal": {
      width: 4 /* Width of horizontal scrollbar */,
    },
    /* For Firefox */
    "&*": {
      scrollbarWidth: "thin",
    },
  };

  const [TherapistList, setTherapistList] = useState([]);

 useEffect(()=>{
  if(localStorage.getItem("User_Type")=="Therapist")
  setDisabledField(true);
   setUserType(localStorage.getItem("User_Type"));
 },[])

 useEffect(()=>{
if(requestStatus=="Completed")
setDisabledField(true);
 },[requestStatus])
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/Users/getUsers?userType=${"Therapist"}`,
        {
          headers,
        }
      )
      .then((response) => {
        var prac = [];
        response.forEach((field) => {
          prac.push({
            label: field.userName,
            value: field.id,
          });
        });
        setTherapistList(prac);
        if(PaymentId>0){
          axios
          .get(`${process.env.REACT_APP_API_URL}/payment/getPaymentRequestById?id=${PaymentId}`, {
            headers
          })
          .then(response => {
            // setEmergencycontantid(response.id)
            fields.forEach(field => {
              if(field=="paymentDate")
              {
                setValue(field, response[field].split("T")[0]);   
              }
              else
              setValue(field, response[field]); 
            
              if(field=="status"){
                setRequestStatus(response[field])
              }
            
              if(field=="userId")
              setTherapistID(response[field]); 

              if(field=="paymentMode")
              setmethod(response[field]); 
    
            });
    
          })
          .catch(error => { });
        }
       
      })
      .catch((error) => {});
  }, []); 



  const processPaymentHandler=()=>{
    if(method=="cash"){

      axios
      .get(`${process.env.REACT_APP_API_URL}/payment/processCashPaymentRequest?paymentId=${PaymentId}`, {
        headers
      })
      .then(response => {
        alert.success("Cash Payment processed successfully ", {
          type: "success",
          onClose: () => {
            navigate("/PaymentMethod");
          },
        });
      })
      .catch(error => { });

    }else{
      navigate('/ProcessPayment', { state: { paymentId: PaymentId, payMethod:method } })
    }
    

  }
 
 
  return (
    <React.Fragment>
      
      <Grid container>
        <Box sx={style}>
          <Grid container spacing={1} style={{ marginBottom: "20px" }}>
           
            <Grid
                  container
                  style={{
                    backgroundColor: "#356F60",
                    position: "sticky",
                    height: "50px",
                  }}
                > 
                  <Grid item xs={8}>
                  <Typography style={{ fontSize: "20px", fontWeight: "bold", color:"white", marginTop:"22px", marginLeft:"20px" }}>
                {userType=="Therapist"?"Process Payment":"Request New Payment"}
              </Typography>
              </Grid>
              <Grid item xs={4}>
                    <CancelOutlinedIcon
                      onClick={handleCancel}
                      fontSize="large"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        cursor: "pointer",
                        float: "right",
                      }}
                    ></CancelOutlinedIcon>
                  </Grid>
                </Grid>
               <Grid container spacing={1}>
               <Grid item xs={4}>
               <Typography
                style={{ fontSize: "13px", fontWeight: "bold", color: "black"  , marginTop : '20px', marginLeft:"20px"}}
              >
                Payment Request to Therapist
              </Typography>
                  </Grid>

                  <Grid item xs={7} style={{ marginTop:"20px" }}>
                    <Controller
                      name="userId"
                      control={control}
                     
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          isDisabled={disabledField}
                          fullWidth
                          value={TherapistList.find(
                            (option) => option.value === TherapistID
                          )}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption.value);
                          }}
                          options={TherapistList}
                          error={errors.userId}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 1000,
                            }),
                          }}
                        />
                      )}
                    />
                     <p style={{ color: 'red', fontSize: '14px' }}>{errors.userId?.message}</p>
               

                </Grid>
               </Grid>
               
               
               <Grid container spacing={1}>

               <Grid item xs={4}>
               <Typography
                style={{ fontSize: "13px", fontWeight: "bold", color: "black"  , marginTop : '30px', marginLeft:"20px"}}
              >
                Payment Amount
              </Typography>
                  </Grid>

                  <Grid item xs={7} style={{marginTop:'10px'}}>

                  <Controller
                  name='totalPayment'
                  {...register('totalPayment')}
                  control={control}
                  disabled={disabledField}
                  render={({ field }) => (
                    <TextField
                    type='number'
                    size="small"
                    label="Payment Amount"
                    placeholder="please enter payment amount"
                    InputLabelProps={{
                      shrink: true,
                    }}
                     {...field}
                      // onChange={event =>  event.target.value}
                      fullWidth
                      error={errors.totalPayment}
                    />
                  )}
                />
                 <p style={{ color: 'red', fontSize: '14px' }}>{errors.totalPayment?.message}</p>
         
          
                       
       
           </Grid>
               </Grid>
           
          
               <Grid container spacing={1}>

<Grid item xs={4}>
<Typography
 style={{ fontSize: "13px", fontWeight: "bold", color: "black"  , marginTop : '20px', marginLeft:"20px"}}
>
 Mode of Payment
</Typography>
   </Grid>




   <Grid item xs={7} style={{marginTop:'20px'}}>

   <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <div>
              <input
                type="radio"
                value="Cash"
                disabled={disabledField}
                name="paymentMode"
                checked={method === "cash"}
                onClick={() => {
                  setValue("paymentMode", "cash");
                  setmethod("cash")
                }}
                style={{
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>Cash</label>

              <input
                type="radio"
                value="Cheque"
                name="paymentMode"
                disabled={disabledField}
                checked={method === "Cheque"}
                onClick={() => {
                  setValue("paymentMode", "Cheque");
                  setmethod("Cheque");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>Cheque</label>


              <input
                type="radio"
                value="creditcard"
                name="paymentMode"
                disabled={disabledField}
                checked={method === "creditcard"}
                onClick={() => {
                  setValue("paymentMode", "creditcard");
                  setmethod("creditcard");
                }}
                style={{
                  marginLeft: "30px",
                  transform: "scale(1.0)",
                  marginRight: "5px",
                }}
              />
              {""}
              <label style={{ fontSize: "15px", fontWeight: "normal" }}>Credit Card</label>
             
            </div>
          </RadioGroup>

        

</Grid>
</Grid>


<Grid container spacing={1}>


   <Grid item xs={11} style={{marginTop:'20px', marginLeft:"20px"}}>

   {/* <Controller
                  name='paymentDate'
                  {...register('paymentDate')}
                  control={control}
                  // disabled={disabledField}
                  render={({ field }) => (
                    <TextField
                    type='date'
                    size="small"
                    label="Payment Due Date"
                    placeholder="please enter Payment Due Date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                     {...field}
                      // onChange={event =>  event.target.value}
                      fullWidth
                      error={!!errors.paymentDate}
                      helperText={errors.paymentDate?.message}
                      {...field}
                    />
                  )}
                />
                 <p style={{ color: 'red', fontSize: '14px' }}>{errors.paymentDate?.message}</p> */}
                        <Controller
  name="paymentDate"
  control={control}
  render={({ field: { onChange, value, ...field } }) => (
    <TextField
      type="date"
      size="small"
      label="Payment Due Date"
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
      error={!!errors.paymentDate}
      helperText={errors.paymentDate?.message}
      value={value ? new Date(value).toISOString().split('T')[0] : ''}
      onChange={(e) => onChange(e.target.value)}
      {...field}
    />
  )}
/>


</Grid>
</Grid>


<Grid container spacing={1}>


   <Grid item xs={11} style={{marginTop:'20px', marginLeft:"20px"}}>

   <Controller
                  name='description'
                  {...register('description')}
                  control={control}
                  disabled={disabledField}
                  render={({ field }) => (
                    <TextField
                    type='textarea'
                    
                    size="small"
                    label="Payment Description"
                    placeholder="please provide payment description"
                    InputLabelProps={{
                      shrink: true,
                    }}
                     {...field}
                      // onChange={event =>  event.target.value}
                      fullWidth
                      
                    />
                  )}
                />

</Grid>
</Grid>
           <Grid item xs={12} style={{marginLeft:"-20px", marginTop:"20px"}}>

              {userType=="Therapist"?(<>
                <Button
                    className="Button"
                    hidden={requestStatus=="Completed"}
                    onClick={processPaymentHandler}
                    style={{
                      borderRadius: "10px",
                      color: "white",
                      height: "40px",
                      width : '110px',
                      borderColor: "#EA7772",
                      fontSize: "14px",
                      fontWeight: "bold",
                      float : 'right'
                    }}
                    type="button"
                  >
                    Pay
                  </Button>
               </>):(<>
                  <Button
                    className="Button"
                    hidden={requestStatus=="Completed"}
                    onClick={handleSubmit(onSubmit)}
                    style={{
                      borderRadius: "10px",
                      color: "white",
                      height: "40px",
                      width : '110px',
                      borderColor: "#EA7772",
                      fontSize: "14px",
                      fontWeight: "bold",
                      float : 'right'
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                  {method == 'cash' || method == 'Cheque'?(<>
                    <Button
                    className="Button"
                    hidden={requestStatus=="Completed"}
                    onClick={handleSubmit(onSubmit1)}
                    style={{
                      borderRadius: "10px",
                      color: "white",
                      height: "40px",
                      width : '180px',
                      borderColor: "#EA7772",
                      fontSize: "14px",
                      fontWeight: "bold",
                      float : 'right'
                    }}
                    type="submit"
                  >
                    Save as Complete
                  </Button>
                  
                  
                  </>):(<></>)}
                  
                  
                  </>)

            }
           
            </Grid>
        
          </Grid>
        </Box>
      </Grid>
    </React.Fragment>
  );
};
export default TakePayment;
